import React from 'react';
import { IHeaderProps } from './Header.types';
import './Header.scss';
import logo from '../../media/voyce-logo.png';
import Button from '../Button';
import UserControls from '../UserControls';

const Header: React.FC<IHeaderProps> = ({
  buttonText,
  user,
  handleOnClick,
}) => {
  return (
    <header className="header-wrapper">
      <div className="header">
        <img src={logo} alt="Voyce Logo" className="header__logo" />
        <div className="header__right-actions">
          <Button onClick={handleOnClick} className="header__btn">
            {buttonText}
          </Button>
          <UserControls user={user} />
        </div>
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import { IUserControlsProps } from './UserControls.types';
import './UserControls.scss';
import Tippy from '@tippyjs/react';
import { ReactComponent as LogoutIcon } from '../../media/icons/logout.svg';
import 'tippy.js/themes/light.css';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../utils/hooks/use-auth';

const UserControls: React.FC<IUserControlsProps> = ({ user }) => {
  const history = useHistory();
  const auth = useAuth();

  const renderOptions = () => {
    return (
      <div className="options">
        <button
          onClick={() => {
            console.log('hi');
          }}
          className="options__btn"
        >
          Account Settings
        </button>
        <button
          onClick={() => {
            auth.signout(() => history.push('/'));
          }}
          className="options__btn"
        >
          Sign Out
          <LogoutIcon className="icon" />
        </button>
      </div>
    );
  };
  return (
    <Tippy
      content={renderOptions()}
      hideOnClick={true}
      trigger="click"
      interactive={true}
      theme="light"
    >
      <div className="user-controls">{user}</div>
    </Tippy>
  );
};

export default UserControls;

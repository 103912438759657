import { decodedSigs } from '../sigCodes';

export const decodeSig = (sigCodes: string): string => {
  const codes = sigCodes.split(' ');
  const translatedCodes = codes.map((code: string) => {
    const nonAlphaNumericIndex = code.search(/\W/g);
    const endIndex =
      nonAlphaNumericIndex < 0 ? code.length : nonAlphaNumericIndex;
    const sigCode = decodedSigs.find(
      (sigCode) => sigCode.code === code.substring(0, endIndex).toUpperCase()
    );
    return sigCode ? sigCode.english + code.substring(endIndex) : code;
  });
  return translatedCodes.join(' ');
};

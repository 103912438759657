import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';

import Button from '../../components/Button';
import PageLayout from '../../components/PageLayout';
import RequestList from '../../components/RequestList';
import { ReactComponent as AddRequestIcon } from '../../media/icons/add-request-icon.svg';
import CancellationModal from '../../modals/CancellationModal';
import NewRequestModal from '../../modals/NewRequestModal';
import {
  CurrentRequest,
  PastRequest,
  Status,
} from '../../utils/dataBuilder/types';
import {
  columns as currentRequestColumns,
  makeActionsColumn,
} from './columns/current-requests';
import {
  makePastActionsColumn,
  columns as pastRequestColumns,
} from './columns/past-requests';
import { ICancellationInfo, IHomePageProps } from './HomePage.types';

import './HomePage.scss';
import 'tippy.js/dist/tippy.css';
import { PdfTempate } from './PdfTemplate';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

const HomePage: React.FC<IHomePageProps> = () => {
  const [currentRequestsData, setCurrentRequestsData] = React.useState<
    CurrentRequest[]
  >([]);
  const [currentRequestsLoading, setCurrentRequestsLoading] = React.useState(
    true
  );
  const [pastRequestsData, setPastRequestsData] = React.useState<PastRequest[]>(
    []
  );
  const [pastRequestsLoading, setPastRequestsLoading] = React.useState(true);
  const [currentRequestsCount, setCurrentRequestsCount] = React.useState(-1);
  const [pastRequestsCount, setPastRequestsCount] = React.useState(-1);

  const fetchCurrentRequestsIdRef = React.useRef(0);
  const fetchPastRequestsIdRef = React.useRef(0);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [
    cancellationInfo,
    setCancellationInfo,
  ] = React.useState<ICancellationInfo>();

  const [currentRequestsPage, setCurrentRequestsPage] = React.useState(0);
  // const currentRequestsPageRef = React.useRef<number>();
  const [pastRequestsPage, setPastRequestsPage] = React.useState(0);
  const pastRequestsPageRef = React.useRef<number>();
  pastRequestsPageRef.current = pastRequestsPage;

  const [isRequestModalOpen, setIsRequestModalOpen] = React.useState(false);

  const fetchCurrentRequests = React.useCallback(
    (
      pageIndex: number,
      shouldFetchCount?: boolean,
      shouldNotRenderLoading?: boolean
    ) => {
      !shouldNotRenderLoading && setCurrentRequestsLoading(true);
      // Give this fetch an ID
      const fetchId = ++fetchCurrentRequestsIdRef.current;
      setTimeout(() => {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchCurrentRequestsIdRef.current) {
          const apiUrl = `/currentRequests?page=${pageIndex}`;
          axios
            .get(apiUrl)
            .then((result) => {
              const currentRequests = result.data.data;
              setCurrentRequestsData(currentRequests);
              !shouldNotRenderLoading && setCurrentRequestsLoading(false);
              if (
                fetchCurrentRequestsIdRef.current !== 1 &&
                currentRequestsData.length !== currentRequests.length
              ) {
                fetchPastRequests(pastRequestsPageRef.current || 0, true, true);
              }
            })
            .catch((error) => console.log(error));
          shouldFetchCount && fetchCurrentRequestsCount();
        }
      }, 500);
    },
    []
  );

  const fetchPastRequests = React.useCallback(
    (
      pageIndex: number,
      shouldFetchCount?: boolean,
      shouldNotRenderLoading?: boolean
    ) => {
      !shouldNotRenderLoading && setPastRequestsLoading(true);
      const fetchId = ++fetchPastRequestsIdRef.current;
      setTimeout(() => {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchPastRequestsIdRef.current) {
          const apiUrl = `/pastRequests?page=${pageIndex}`;
          axios
            .get(apiUrl)
            .then((result) => {
              const pastRequests = result.data.data;
              setPastRequestsData(pastRequests);
              !shouldNotRenderLoading && setPastRequestsLoading(false);
            })
            .catch((error) => console.log(error));
          shouldFetchCount && fetchPastRequestsCount();
        }
      }, 700);
    },
    []
  );

  const fetchCurrentRequestsCount = React.useCallback(() => {
    // setCurrentRequestsCount(-1);
    setTimeout(() => {
      const apiUrl = `/currentRequests/count`;
      axios
        .get(apiUrl)
        .then((result) => {
          setCurrentRequestsCount(result.data.count);
        })
        .catch((error) => console.log(error));
    }, 500);
  }, []);

  const fetchPastRequestsCount = React.useCallback(() => {
    // setPastRequestsCount(-1);
    setTimeout(() => {
      const apiUrl = `/pastRequests/count`;
      axios
        .get(apiUrl)
        .then((result) => {
          setPastRequestsCount(result.data.count);
        })
        .catch((error) => console.log(error));
    }, 500);
  }, []);

  const cancelRequest = React.useCallback((id: string, rxNumber: string) => {
    setCurrentRequestsLoading(true);
    setTimeout(() => {
      const apiUrl = `/cancelRequest`;
      axios
        .post(apiUrl, { id })
        .then(() => {
          fetchCurrentRequests(currentRequestsPage, true);
          toast(`Translation request for Rx #${rxNumber} has been deleted.`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch((error) => console.log(error));
    }, 1000);
  }, []);

  const downloadRequest = React.useCallback(
    (id: string, currentRequests: CurrentRequest[]) => {
      setTimeout(() => {
        const apiUrl = '/download';
        axios
          .post(apiUrl, { id })
          .then(async (response) => {
            const doc = (
              <PdfTempate
                language={response.data.language}
                translation={response.data.translation}
                englishTranslation={response.data.originalText}
              />
            );
            const asPdf = pdf();
            asPdf.updateContainer(doc);
            const blob = await asPdf.toBlob();
            saveAs(blob, `${response.data.rxNumber}-translation.pdf`);

            const updatedCurrentRequests = [...currentRequests];
            const updateIndex = updatedCurrentRequests.findIndex(
              (request) => request.id === id
            );
            const requestToUpdate = updatedCurrentRequests[updateIndex];
            updatedCurrentRequests[updateIndex] = {
              ...requestToUpdate,
              status: Status.TranslationDownloaded,
            };
            setCurrentRequestsData(updatedCurrentRequests);
          })
          .catch((error) => console.log(error));
      }, 500);
    },
    []
  );

  const addRequest = React.useCallback(
    (
      rxNumber: string,
      language: string,
      sigCodes: string[],
      additionalText: string
    ) => {
      setCurrentRequestsLoading(true);
      setTimeout(() => {
        const apiUrl = `/addRequest`;
        axios
          .post(apiUrl, { rxNumber, language, sigCodes, additionalText })
          .then(() => {
            fetchCurrentRequests(currentRequestsPage, true);
            fetchCurrentRequestsCount();
            toast(
              <div>
                Translation request for #{rxNumber} has been submitted.
                <br />
                <br />
                Once the translation is complete, you will be able to download
                it.
              </div>,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          })
          .catch((error) => console.log(error));
      }, 1000);
    },
    []
  );

  const handleDeleteRequest = (info: ICancellationInfo) => {
    setIsDeleteModalOpen(true);
    setCancellationInfo(info);
  };

  const handleDownload = (
    rxNumber: string,
    id: string,
    firstDownload: boolean
  ) => {
    toast(
      <div>
        Your download for Rx #{rxNumber} has been initiated.
        {firstDownload && (
          <div>
            <br />
            Once the download is complete, you will be able to view the request
            and redownloaded the translation in Past Requests.
          </div>
        )}
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );
    downloadRequest(id, currentRequestsData);
  };

  React.useEffect(() => {
    fetchCurrentRequestsCount();
    fetchPastRequestsCount();

    const interval = setInterval(() => {
      fetchCurrentRequests(currentRequestsPage, false, true);
      fetchCurrentRequestsCount();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {cancellationInfo && (
        <CancellationModal
          id={cancellationInfo.id}
          rxNumber={cancellationInfo.rxNumber}
          language={cancellationInfo.language}
          contentToTranslate={cancellationInfo.contentToTranslate}
          handleModalClose={() => setIsDeleteModalOpen(false)}
          handleCancelRequest={cancelRequest}
          showModal={isDeleteModalOpen}
        />
      )}
      <NewRequestModal
        handleModalClose={() => setIsRequestModalOpen(false)}
        showModal={isRequestModalOpen}
        handleSubmitRequest={addRequest}
      />
      <PageLayout
        content={
          <>
            <RequestList
              className="current-requests"
              title="Current Requests"
              icon={<AddRequestIcon />}
              tooltipContent="Create a new translation request"
              onClickIcon={() => setIsRequestModalOpen(true)}
              data={currentRequestsData}
              columns={[
                ...currentRequestColumns,
                makeActionsColumn(handleDeleteRequest, handleDownload),
              ]}
              totalCount={currentRequestsCount}
              loading={currentRequestsLoading}
              noDataComponent={
                <div className="no-data">
                  There are no current translation requests.
                  <Button onClick={() => setIsRequestModalOpen(true)}>
                    New Translation
                  </Button>
                </div>
              }
              fetchData={fetchCurrentRequests}
              setPageIndex={(pageIndex: number) => {
                setCurrentRequestsPage(pageIndex);
              }}
            />
            <RequestList
              className="past-requests"
              title="Past Requests"
              data={pastRequestsData}
              columns={[
                ...pastRequestColumns,
                makePastActionsColumn(handleDownload),
              ]}
              totalCount={pastRequestsCount}
              loading={pastRequestsLoading}
              noDataComponent={
                <div className="no-data">There are no past requests.</div>
              }
              fetchData={fetchPastRequests}
              setPageIndex={(pageIndex: number) => {
                setPastRequestsPage(pageIndex);
              }}
            />
          </>
        }
        headerButtonOnClick={() => setIsRequestModalOpen(true)}
      />
    </>
  );
};

export default HomePage;

export enum Status {
  WaitingForTranslator = 'Waiting For Translator',
  Translating = 'Translating',
  TranslationComplete = 'Translation Complete',
  TranslationDownloaded = 'Translation Downloaded',
}

export interface PastRequest {
  id: string;
  rxNumber: string;
  language: string;
  requestTime: Date;
  contentToTranslate: string;
  translator?: string;
}

export interface CurrentRequest extends PastRequest {
  status: Status;
  elapsedTime: number;
}

import React from 'react';
import { IForgotPasswordProps } from './ForgotPassword.types';
import './ForgotPassword.scss';
import logo from '../../media/voyce-logo.png';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';

const ForgotPassword: React.FC<IForgotPasswordProps> = () => {
  const [userName, setUsername] = React.useState<string>();
  // const [password, setPassword] = React.useState<string>();

  return (
    <div className="forgot-password">
      <div className="forgot-password__content-wrapper">
        <div className="forgot-password__content">
          <div className="forgot-password__logo">
            <img src={logo} alt="Voyce Logo" className="header__logo" />
            <div>Rx Translation</div>
          </div>

          <div className="forgot-password__form">
            <div className="forgot-password__header">Forgot Password?</div>
            <div className="forgot-password__details">
              Enter the email associated with your account and we will send you
              instructions on how to reset your password.
            </div>
            <div className="forgot-password__input-group">
              <label>Email</label>
              <input
                type="text"
                className="forgot-password__input"
                onChange={(e) => setUsername(e.target.value)}
                autoComplete="off"
                placeholder="email@walgreens.com"
              />
            </div>
            <Button
              onClick={() => console.log('reset')}
              className="forgot-password__button"
            >
              Send Reset Instructions
            </Button>
            <Link to="/login" className="forgot-password__link">
              I already have an account
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import faker from 'faker';
import { getLanguageLabels } from '../../languages';
import { topDirections, topSigCodes } from '../../sigCodes';
import { translators } from './constants';
import { PastRequest } from './types';
import { capitalizeFirstLetter, createRxNumber } from './utils';

const createPastRequest = (index: number): PastRequest => {
  const languages = getLanguageLabels();
  let language = languages[Math.floor(Math.random() * languages.length)];
  if (Math.random() < 0.65) {
    language = 'Spanish';
  }

  const includeAdditionalText = Math.random() < 0.5;
  const translator = `Translator ${
    translators[Math.floor(Math.random() * translators.length)]
  }`;
  const contentToTranslate = includeAdditionalText
    ? capitalizeFirstLetter(
        topDirections[
          Math.floor(Math.random() * topDirections.length)
        ].toLowerCase()
      )
    : topSigCodes[Math.floor(Math.random() * topSigCodes.length)];

  return {
    id: faker.datatype.uuid().toString(),
    rxNumber: createRxNumber(),
    language,
    requestTime: faker.date.past(1),
    translator: includeAdditionalText ? translator : undefined,
    contentToTranslate,
  };
};

export const createPastRequestsList = (listSize: number): PastRequest[] => {
  const pastRequestsList = [];
  for (let i = 0; i < listSize; i++) {
    pastRequestsList.push(createPastRequest(i));
  }

  pastRequestsList.sort((a, b) => (a.requestTime > b.requestTime ? -1 : 1));

  return pastRequestsList;
};

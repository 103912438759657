import React from 'react';
import {
  INewRequestInfo,
  INewRequestModalProps,
  IRequestInfoErrors,
} from './NewRequestModal.types';
import './NewRequestModal.scss';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Select from 'react-select';
import { sigCodeOptions } from '../../sigCodes';
import { languageOptions } from '../../languages';
import FormInput from '../../components/FormInput';
import { isEqual } from 'lodash';
import classNames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import { decodeSig } from '../../utils/translation';

const NewRequestModal: React.FC<INewRequestModalProps> = ({
  handleModalClose,
  handleSubmitRequest,
  showModal,
}) => {
  const MAX_CHARS_ADDITIONAL_TEXT = 150;
  const initialInfo: INewRequestInfo = {
    rxNumber: '',
    language: '',
    sigCodes: [],
    additionalText: '',
  };

  const initialErrors: IRequestInfoErrors = {
    rxNumberError: false,
    languageError: false,
    noContentError: false,
  };

  const [info, setInfo] = React.useState<INewRequestInfo>(initialInfo);
  const [errors, setErrors] = React.useState<IRequestInfoErrors>(initialErrors);

  const isInfoValidated = (): boolean => {
    const { rxNumber, language, sigCodes, additionalText } = info;
    let infoErrors = { ...initialErrors };
    if (!rxNumber) {
      infoErrors = { ...infoErrors, rxNumberError: true };
    }
    if (!language) {
      infoErrors = { ...infoErrors, languageError: true };
    }
    if (!sigCodes.length && !additionalText) {
      infoErrors = {
        ...infoErrors,
        noContentError: true,
      };
    }

    setErrors(infoErrors);
    return isEqual(infoErrors, initialErrors);
  };

  const closeModal = () => {
    setErrors(initialErrors);
    setInfo(initialInfo);
    handleModalClose();
  };

  return (
    <Modal
      show={showModal}
      handleClose={closeModal}
      className="new-request-modal"
      header="New Translation Request"
    >
      <div className="new-request-modal__content">
        <form className="new-request-modal__form">
          <FormInput
            keyText="Rx Number:"
            value={
              <input
                type="text"
                name="rx-number"
                className={classNames('new-request-modal__input--small', {
                  error: errors.rxNumberError,
                })}
                placeholder="Prescription number..."
                onChange={(e) => {
                  setInfo({ ...info, rxNumber: e.target.value });
                  if (e.target.value.length && errors.rxNumberError) {
                    setErrors({ ...errors, rxNumberError: false });
                  }
                }}
                autoComplete="off"
              />
            }
            error={
              errors.rxNumberError ? 'Please enter a Rx number.' : undefined
            }
          />
          <FormInput
            keyText="Translation Language:"
            value={
              <Select
                options={languageOptions}
                placeholder="Select a language..."
                onChange={(selectedLanguage) => {
                  if (selectedLanguage) {
                    setInfo({ ...info, language: selectedLanguage.value });
                    if (selectedLanguage.value.length && errors.languageError) {
                      setErrors({ ...errors, languageError: false });
                    }
                  }
                }}
                classNamePrefix={`form-select${
                  errors.languageError && '-error'
                }`}
              />
            }
            error={
              errors.languageError
                ? 'Please enter a language to translate the prescription to.'
                : undefined
            }
          />
          <FormInput
            keyText="Sig Codes:"
            value={
              <>
                <CreatableSelect
                  isMulti
                  className="basic-multi-select"
                  onChange={(selectedSigCodes) => {
                    const sigCodeValues = selectedSigCodes.map(
                      (codeOption) => codeOption.value
                    );
                    if (sigCodeValues) {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      setInfo({ ...info, sigCodes: sigCodeValues as any });
                      if (sigCodeValues.length && errors.noContentError) {
                        setErrors({ ...errors, noContentError: false });
                      }
                    }
                  }}
                  options={sigCodeOptions}
                  formatCreateLabel={(input) => input}
                  getNewOptionData={(inputValue) => ({
                    value: inputValue,
                    label: inputValue.toUpperCase(),
                  })}
                  isValidNewOption={(inputValue) =>
                    /\d/.test(inputValue) || !/^[a-z0-9]+$/i.test(inputValue)
                  }
                  classNamePrefix={`form-select${
                    errors.noContentError && '-error'
                  }`}
                />
                {!!info.sigCodes.length && (
                  <div className="new-request-modal__decoding">
                    English: {decodeSig(info.sigCodes.join(' '))}
                  </div>
                )}
              </>
            }
            error={
              errors.noContentError
                ? 'Please either enter Sig codes or additional text to translate.'
                : undefined
            }
          />
          <FormInput
            keyText="Additional Text:"
            value={
              <>
                <textarea
                  className={classNames('new-request-modal__input--large', {
                    error: errors.noContentError,
                  })}
                  value={info.additionalText}
                  onChange={(e) => {
                    setInfo({ ...info, additionalText: e.target.value });
                    if (e.target.value.length && errors.noContentError) {
                      setErrors({ ...errors, noContentError: false });
                    }
                  }}
                  placeholder="Additional text to translate..."
                  maxLength={MAX_CHARS_ADDITIONAL_TEXT}
                />
                <div className={'new-request-modal__char-count'}>
                  {info.additionalText.length}/{MAX_CHARS_ADDITIONAL_TEXT}{' '}
                  characters
                </div>
              </>
            }
            error={
              errors.noContentError
                ? 'Please either enter Sig codes or additional text to translate.'
                : info.additionalText.length >= MAX_CHARS_ADDITIONAL_TEXT
                ? `Must be at most ${MAX_CHARS_ADDITIONAL_TEXT} characters.`
                : undefined
            }
          />
        </form>
        <div className="new-request-modal__buttons">
          <Button
            onClick={() => {
              if (isInfoValidated()) {
                closeModal();
                handleSubmitRequest(
                  info.rxNumber,
                  info.language,
                  info.sigCodes,
                  info.additionalText
                );
              }
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NewRequestModal;

import React from 'react';
import { IButtonProps } from './Button.types';
import './Button.scss';
import classNames from 'classnames';

const Button: React.FC<IButtonProps> = ({
  children,
  className,
  onClick,
  icon,
  disabled,
  variant,
}) => {
  return (
    <>
      {/* <div className="button-overlay"></div> */}
      <button
        className={classNames('button', `button--${variant}`, className, {
          icon,
        })}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </>
  );
};

export default Button;

import { startCase } from 'lodash';

const languages = [
  'Amharic',
  'Arabic',
  'Burmese',
  'Chinese',
  'Farsi',
  'Korean',
  'Nepali',
  'Pashtu',
  'Romanian',
  'Russian',
  'Somali',
  'Spanish',
  'Swahili',
  'Vietnamese',
];

export const languageOptions = languages.map((language) => ({
  value: language.toLowerCase(),
  label: startCase(language),
}));

export const getLanguageLabels = (): string[] => {
  return languageOptions.map((option) => option.label);
};

import React from 'react';
import { IIconButtonProps } from './IconButton.types';
import './IconButton.scss';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';

const IconButton: React.FC<IIconButtonProps> = ({
  children,
  color,
  tooltip,
  ...rest
}) => {
  const button = (
    <button
      className={classNames(
        'icon-button',
        `icon-button--${color || 'default'}`
      )}
      {...rest}
    >
      {children}
    </button>
  );
  return tooltip ? (
    <Tippy content={tooltip} arrow={false}>
      {button}
    </Tippy>
  ) : (
    <div>{button}</div>
  );
};

export default IconButton;

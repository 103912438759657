import React from 'react';
import { ICancellationModalProps } from './CancellationModal.types';
import './CancellationModal.scss';
import Button from '../../components/Button';
import Modal from '../../components/Modal';

const CancellationModal: React.FC<ICancellationModalProps> = ({
  id,
  rxNumber,
  language,
  contentToTranslate,
  handleModalClose,
  handleCancelRequest,
  showModal,
}) => {
  return (
    <Modal
      show={showModal}
      handleClose={handleModalClose}
      className="cancel-request-modal"
      header="Delete Translation Request"
    >
      <div className="cancellation-modal">
        <div className="cancellation-modal__text">
          Confirming deletion of translation request for <b>Rx #{rxNumber}</b>
          <div className="cancellation-modal__details">
            <div className="cancellation-modal__key-value">
              <div className="cancellation-modal__key">
                Translation Language:
              </div>
              <div className="cancellation-modal__value">{language}</div>
            </div>
            <div className="cancellation-modal__key-value">
              <div className="cancellation-modal__key">
                Content to Translate:
              </div>
              <div className="cancellation-modal__value">
                {contentToTranslate}
              </div>
            </div>
          </div>
        </div>
        <div className="cancellation-modal__buttons">
          <Button onClick={handleModalClose} variant="gray">
            Close
          </Button>
          <Button
            onClick={() => {
              handleModalClose();
              handleCancelRequest(id, rxNumber);
            }}
          >
            Delete Translation
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancellationModal;

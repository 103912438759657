import React from 'react';
import { ILoginProps } from './Login.types';
import './Login.scss';
import logo from '../../media/voyce-logo.png';
import Button from '../../components/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../utils/hooks/use-auth';
import LoadingSpinner from '../../components/LoadingSpinner';

const Login: React.FC<ILoginProps> = () => {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();

  const [loginError, setLoginError] = React.useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { from } = (location.state as any) || { from: { pathname: '/' } };
  const login = (username?: string, password?: string) => {
    setLoading(true);
    if (username && password) {
      auth
        .signin(
          () => history.replace(from),
          () => setLoading(false),
          username,
          ''
        )
        .then((response) => {
          if (response) {
            setLoginError(false);
          } else {
            setLoginError(true);
          }
        });
    } else {
      setLoginError(true);
      setLoading(false);
    }
  };

  const [username, setUsername] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <div className="login">
      <div className="login__content-wrapper">
        <div className="login__content">
          <div className="login__logo">
            <img src={logo} alt="Voyce Logo" className="header__logo" />
            <div>Rx Translation</div>
          </div>
          <div className="login__form">
            <div className="login__header">Welcome</div>
            {loginError && (
              <div className="login__error">
                The credentials you have provided are incorrect. Please try
                again.
              </div>
            )}
            <div className="login__input-group">
              <label>Username</label>
              <input
                type="text"
                className="login__input"
                onChange={(e) => setUsername(e.target.value)}
                autoComplete="off"
                placeholder="email@walgreens.com"
              />
            </div>
            <div className="login__input-group">
              <label>Password</label>
              <input
                type="password"
                className="login__input"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
              />
              <Link to="/forgot-password" className="login__forgot-password">
                Forgot password?
              </Link>
            </div>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <Button
                onClick={() => login(username, password)}
                className="login__sign-in"
              >
                Sign In
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

export const getRandomInt = (): number => {
  return Math.floor(Math.random() * 9);
};

export const createRxNumber = (): string => {
  return `${getRandomInt()}${getRandomInt()}${getRandomInt()}${getRandomInt()}${getRandomInt()}${getRandomInt()}${getRandomInt()}-${getRandomInt()}${getRandomInt()}${getRandomInt()}${getRandomInt()}${getRandomInt()}`;
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

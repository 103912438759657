import React from 'react';

import Header from '../Header';
import { IPageLayoutProps } from './PageLayout.types';

import './PageLayout.scss';

const PageLayout: React.FC<IPageLayoutProps> = ({
  content,
  headerButtonOnClick,
}) => {
  return (
    <div className="page-layout">
      <Header
        buttonText="New Translation"
        user="D"
        handleOnClick={headerButtonOnClick}
      />
      <div className="page-layout__content">{content}</div>
    </div>
  );
};

export default PageLayout;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Cell, Column } from 'react-table';
import StatusPill from '../../../components/StatusPill';
import { CurrentRequest, Status } from '../../../utils/dataBuilder/types';
import { formatDate } from '../../../utils/date';
import { ReactComponent as DownloadIcon } from '../../../media/icons/download-button.svg';
import { ReactComponent as DownloadGrayIcon } from '../../../media/icons/download-button--gray.svg';
import { ReactComponent as DeleteIcon } from '../../../media/icons/delete.svg';
import '../HomePage.scss';
import { ICancellationInfo } from '../HomePage.types';
import { startCase } from 'lodash';
import IconButton from '../../../components/IconButton';

export const makeActionsColumn = (
  handleDelete: (info: ICancellationInfo) => void,
  handleDownload: (rxNumber: string, id: string, firstDownload: boolean) => void
) => ({
  Header: '',
  id: 'actions',
  Cell: (cell: Cell) => {
    const { id, status, rxNumber, language, contentToTranslate } = cell.row
      .original as CurrentRequest;
    if (status === Status.TranslationDownloaded) {
      return (
        <div className="download-button">
          <IconButton
            onClick={() => handleDownload(rxNumber, id, false)}
            aria-label="download translation for past request"
            tooltip="Download Translation"
          >
            <DownloadGrayIcon />
          </IconButton>
        </div>
      );
    } else if (status === Status.TranslationComplete) {
      return (
        <div className="download-button">
          <IconButton
            onClick={() => handleDownload(rxNumber, id, true)}
            aria-label="download translation for current request"
            color="primary"
            tooltip="Download Translation"
          >
            <DownloadIcon />
          </IconButton>
        </div>
      );
    }

    if (status !== Status.Translating) {
      const onDelete = () => {
        handleDelete({ id, rxNumber, language, contentToTranslate });
      };
      return (
        <div className="delete-button">
          <IconButton
            onClick={onDelete}
            aria-label="delete translation request"
            tooltip="Delete Request"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      );
    }
    return null;
  },
});

export const columns: Column[] = [
  {
    Header: 'Rx Number',
    accessor: 'rxNumber',
    minWidth: 155,
  },
  {
    Header: 'Language',
    accessor: 'language',
    Cell: (cell: Cell) => {
      return startCase(cell.value);
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: Cell) => {
      return <StatusPill status={cell.value} />;
    },
    minWidth: 220,
  },
  {
    Header: 'Request Time',
    accessor: 'requestTime',
    Cell: (cell: Cell) => {
      const { value: requestTime } = cell;
      return formatDate(requestTime);
    },
  },
  {
    Header: 'Elapsed Time',
    accessor: 'elapsedTime',
    Cell: (cell: Cell) => `${cell.value} min`,
  },
  {
    Header: 'Translator',
    accessor: 'translator',
  },
  {
    Header: 'Content to Translate',
    accessor: 'contentToTranslate',
    maxWidth: 300,
  },
];

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
// Hook (use-auth.js)
import React, { useState, useEffect, useContext, createContext } from 'react';
// import * as firebase from "firebase/app";
// import "firebase/auth";

// // Add your Firebase credentials
// firebase.initializeApp({
//   apiKey: "",
//   authDomain: "",
//   projectId: "",
//   appID: "",
// });

interface IAuth {
  user?: string;
  signin: (
    handleSuccess: () => void,
    cleanUp: () => void,
    username: string,
    password: string
  ) => Promise<string | undefined>;
  signout: (cb: () => void) => void;
}

const fakeAuth = {
  isAuthenticated: false,
  signin: (username: string, password: string): Promise<string> => {
    // if (username === 'test@voyceglobal.com') {
    fakeAuth.isAuthenticated = true;
    // } else {
    //   fakeAuth.isAuthenticated = false;
    // }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        fakeAuth.isAuthenticated
          ? resolve('test@voyceglobal.com')
          : reject('Invalid credentials');
      }, 1500);
    });
  },
  signout: (cb: () => void) => {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

const authContext = createContext<IAuth>({
  user: '',
  signin: (
    handleSuccess: () => void,
    cleanUp: () => void,
    username: string,
    password: string
  ) => new Promise<string | undefined>(() => {}),
  signout: (cb: () => void) => {},
});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth: React.FC<any> = ({ children }) => {
  const auth: IAuth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = () => {
  const [user, setUser] = useState<string | undefined>('');

  const signin = (
    handleSuccess: () => void,
    cleanUp: () => void,
    username: string,
    password: string
  ) => {
    return fakeAuth
      .signin(username, password)
      .then((response) => {
        setUser(response);
        handleSuccess();
        return response;
      })
      .catch(() => {
        setUser(undefined);
        return undefined;
      })
      .finally(() => {
        cleanUp();
      });
  };

  const signout = (cb: () => void) => {
    return fakeAuth.signout(() => {
      setUser('');
      cb();
    });
  };

  return {
    user,
    signin,
    signout,
  };
};

// // Provider hook that creates auth object and handles state
// const useProvideAuth = () => {
//   const [user, setUser] = useState(null);

//   // Wrap any Firebase methods we want to use making sure ...
//   // ... to save the user to state.
//   const signin = (email: string, password: string) => {
//     return firebase
//       .auth()
//       .signInWithEmailAndPassword(email, password)
//       .then((response) => {
//         setUser(response.user);
//         return response.user;
//       });
//   };

//   const signup = (email: string, password: string) => {
//     return firebase
//       .auth()
//       .createUserWithEmailAndPassword(email, password)
//       .then((response) => {
//         setUser(response.user);
//         return response.user;
//       });
//   };

//   const signout = () => {
//     return firebase
//       .auth()
//       .signOut()
//       .then(() => {
//         setUser(false);
//       });
//   };

//   const sendPasswordResetEmail = (email: string) => {
//     return firebase
//       .auth()
//       .sendPasswordResetEmail(email)
//       .then(() => {
//         return true;
//       });
//   };

//   const confirmPasswordReset = (code: string, password: string) => {
//     return firebase
//       .auth()
//       .confirmPasswordReset(code, password)
//       .then(() => {
//         return true;
//       });
//   };

//   // Subscribe to user on mount
//   // Because this sets state in the callback it will cause any ...
//   // ... component that utilizes this hook to re-render with the ...
//   // ... latest auth object.
//   useEffect(() => {
//     const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
//       if (user) {
//         setUser(user);
//       } else {
//         setUser(false);
//       }
//     });

//     // Cleanup subscription on unmount
//     return () => unsubscribe();
//   }, []);

//   // Return the user object and auth methods
//   return {
//     user,
//     signin,
//     signup,
//     signout,
//     sendPasswordResetEmail,
//     confirmPasswordReset,
//   };
// };

import classNames from 'classnames';
import React from 'react';

import Table from '../Table';
import { IRequestListProps } from './RequestList.types';

import './RequestList.scss';
import IconButton from '../IconButton';

const RequestList: React.FC<IRequestListProps> = ({
  title,
  data,
  columns,
  icon,
  onClickIcon,
  loading,
  noDataComponent,
  className,
  fetchData,
  totalCount,
  tooltipContent,
  setPageIndex,
}) => {
  return (
    <div className={classNames('request-list', className)}>
      <div className="request-list__header">
        <h1 className="request-list__title">{title}</h1>
        {icon && onClickIcon && (
          <IconButton
            onClick={onClickIcon}
            aria-label="create new translation"
            color="primary"
            tooltip={tooltipContent}
          >
            {icon}
          </IconButton>
        )}
      </div>
      <Table
        columns={columns}
        data={data}
        totalCount={totalCount}
        fetchData={fetchData}
        loading={loading}
        noDataComponent={noDataComponent}
        setPageIndex={setPageIndex}
      />
    </div>
  );
};

export default RequestList;

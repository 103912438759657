export const sigCodeTranslations = [
  {
    code: 'AA',
    english: 'TO THE AFFECTED AREA',
    spanish: 'A LA AREA AFECTADA',
  },
  { code: 'AB', english: 'BOTH', spanish: 'AMBOS' },
  { code: 'ABD', english: 'ABDOMINAL', spanish: 'ABDOMINAL' },
  { code: 'AC', english: 'BEFORE A MEAL', spanish: 'ANTES DE LA COMIDA' },
  { code: 'AD', english: 'RIGHT EAR', spanish: 'OIDO DERECHO' },
  { code: 'ADC', english: 'AFTER FOOD', spanish: 'ANTES DE LAS COMIDAS' },
  { code: 'ADM', english: 'ADMINISTER', spanish: 'ADMINISTRE' },
  {
    code: 'AEA',
    english: 'IN AFFECTED EAR(S)',
    spanish: 'EN OIDO(S) AFECTADO(S)',
  },
  {
    code: 'AEY',
    english: 'IN AFFECTED EYE(S)',
    spanish: 'EN OJO(S) AFECTADO(S)',
  },
  {
    code: 'AHA',
    english: 'A LA HORA DE ACOSTARSE',
    spanish: 'A LA HORA DE ACOSTARSE',
  },
  {
    code: 'AIDO',
    english: 'AFTER INITIAL DOSE',
    spanish: 'DESPUES DE LA DOSIS INICIAL',
  },
  {
    code: 'ALN',
    english: 'OF OPIOID EMERGENCY PERSIST, ALTERNATE NOSTRILS',
    spanish: 'LA EMERGENCIA OPIODA PERSISTEN, ALTERNE LA FOS',
  },
  { code: 'ALT', english: 'ALTERNATE', spanish: 'SUPLENTE' },
  { code: 'AMP', english: 'AMPULE', spanish: 'AMPOLLETA' },
  { code: 'AMT', english: 'AMOUNT', spanish: 'CANTIDAD' },
  { code: 'AND', english: 'AND', spanish: 'Y' },
  { code: 'AOS', english: 'AT ONSET', spanish: 'EN INICIO' },
  { code: 'AP', english: 'APLICAR', spanish: 'APLICAR' },
  { code: 'APL', english: 'APPLICATORFUL', spanish: 'APLICADOR LLENO' },
  { code: 'APP', english: 'APPLY', spanish: 'APLICAR' },
  { code: 'APR', english: 'APPLY TO RASH', spanish: 'APLICAR A LA ERUPCION' },
  { code: 'APS', english: 'APPLICATORSFUL', spanish: 'APLICADORES LLENOS' },
  {
    code: 'ASD',
    english: 'APPLY SPARINGLY WITH EACH DIAPER CHANGE',
    spanish: 'APLICAR EXPORADICAMENTE EN CADA CAMBIO DE PANAL',
  },
  { code: 'ATC', english: 'AROUND THE CLOCK', spanish: 'LAS 24 HORAS' },
  {
    code: 'ATM',
    english: 'CALL 911. USE AS DIRECTED WITH NALOXONE NEEDLELESS',
    spanish:
      'LLAME AL 911. USAR SEGÚN LAS INSTRUCCIONES CON NALOXONA SIN AGUJAS',
  },
  { code: 'ATN', english: 'AT NIGHT', spanish: 'EN LA NOCHE' },
  { code: 'AU', english: 'BOTH EARS', spanish: 'AMBOS OIDOS' },
  {
    code: 'AVN',
    english: 'USE 1 AMPULE VIA NEBULIZER',
    spanish: 'USE UNA AMPOLETTA VIA NEBULIZADOR',
  },
  { code: 'B', english: 'BEFORE', spanish: 'ANTES' },
  { code: 'BED', english: 'BEDTIME', spanish: 'DE ACOSTARSE' },
  { code: 'BG', english: 'BLOOD GLUCOSE', spanish: 'LA GLUCOSA EN EL SANGRE' },
  { code: 'BID', english: 'TWICE DAILY', spanish: 'DOS VECES AL DIA' },
  {
    code: 'BM',
    english: 'BOWEL MOVEMENT',
    spanish: 'MOVIMIENTO DEL INTESTINO',
  },
  { code: 'BP', english: 'BLOOD PRESSURE', spanish: 'PRESION SANGUINEA' },
  { code: 'BRE', english: 'BREAKFAST', spanish: 'DESAYUNO' },
  { code: 'BS', english: 'BLOOD SUGAR', spanish: 'LA AZÚCAR EN EL SANGRE' },
  { code: 'BTP', english: 'BREAKTHROUGH PAIN', spanish: 'NULL' },
  { code: 'C', english: 'CAPSULE', spanish: 'CAPSULA' },
  { code: 'CA', english: 'CAPSULA', spanish: 'CAPSULA' },
  {
    code: 'CAP',
    english: 'TAKE ONE CAPSULE EVERY 1 HOURS',
    spanish: 'TOMAR UNA CAPSULA CADA 1 HORAS',
  },
  { code: 'CAS', english: 'CAPSULAS', spanish: 'CAPSULAS' },
  { code: 'CFL', english: 'CAPFUL', spanish: 'TAPADERA' },
  { code: 'CH', english: 'CUCHARADA', spanish: 'CUCHARADA' },
  { code: 'CHD', english: 'CUCHARADITA', spanish: 'CUCHARADITA' },
  { code: 'CNC', english: 'COUGH AND CONGESTION', spanish: 'TOS Y CONGESTION' },
  {
    code: 'CNTS',
    english: 'CONTENTS OF 1 PACKET',
    spanish: 'CONTENIDO DE 1 PAQUETE',
  },
  { code: 'COG', english: 'CONGESTION', spanish: 'CONGESTION' },
  { code: 'CON', english: 'CONGESTION', spanish: 'CONGESTION' },
  { code: 'CONS', english: 'CONSECUTIVE', spanish: 'CONSECUTIVOS' },
  { code: 'CONT', english: 'CONTINUE', spanish: 'CONTINUA' },
  { code: 'COU', english: 'FOR COUGH', spanish: 'PARA LA TOS' },
  { code: 'CP', english: 'CHEST PAIN', spanish: 'EL DOLOR EN EL PECHO' },
  {
    code: 'CPS',
    english: 'TAKE 1 CAPSULES EVERY 2 HOURS',
    spanish: 'TOMAR 1 CAPSULAS CADA 2 HORAS',
  },
  { code: 'CRU', english: 'CRUSH', spanish: 'MACHAQUE' },
  { code: 'CS', english: 'CAPSULES', spanish: 'CAPSULAS' },
  {
    code: 'CSN',
    english: 'CUANDO SEA NECESARIO',
    spanish: 'CUANDO SEA NECESARIO',
  },
  { code: 'CSW', english: 'CHEW AND SWALLOW', spanish: 'MASTIQUE Y TOME' },
  {
    code: 'CVD',
    english: 'CUATRO VECES AL DIA',
    spanish: 'CUATRO VECES AL DIA',
  },
  { code: 'D', english: 'DAILY', spanish: 'DIARIAMENTE' },
  { code: 'DAPP', english: 'DENTAL APPOINTMENT', spanish: 'CITA DENTAL' },
  { code: 'DAYS', english: 'DAYS', spanish: 'DIAS' },
  { code: 'DBP', english: 'DIASTOLIC BLOOD PRESSURE', spanish: 'NULL' },
  {
    code: 'DDC',
    english: 'DESPUES DE LAS COMIDAS',
    spanish: 'DESPUES DE LAS COMIDAS',
  },
  { code: 'DH', english: 'DIARRHEA', spanish: 'DIARREA' },
  { code: 'DI', english: 'DIARIAMENTE', spanish: 'DIARIAMENTE' },
  {
    code: 'DID',
    english: 'TWO OR THREE TIMES DAILY FOR WEIGHT LOSS',
    spanish: 'DOS O TRES VECES AL DIA PARA PERDIDA DE PESO',
  },
  { code: 'DIS', english: 'DISSOLVE', spanish: 'DISOLVER' },
  {
    code: 'DIW',
    english: 'DISSOLVE 1 TABLETS IN WATER',
    spanish: 'DISOLVER 1 TABLETA EN AGUA',
  },
  {
    code: 'DNC',
    english: 'TK FOUR CS PO 1 HOUR B DAPP',
    spanish: 'TOMAR CUATRO CS PO UNA HORA ANTES DE LA CITA DENTAL',
  },
  {
    code: 'DNT',
    english: 'TAKE FOUR TS PO 1 HOUR B DAPP',
    spanish: 'TOMAR CUATRO TS PO UNA HORA ANTES DE LA CITA DENTAL',
  },
  { code: 'DPS', english: 'DROPPERSFUL', spanish: 'GOTEROS LLENOS' },
  { code: 'DR', english: 'DISCARD REMAINDER', spanish: 'DESECHE EL RESTO' },
  { code: 'DRK', english: 'DRINK', spanish: 'TOME' },
  { code: 'DRP', english: 'DROPPERFUL', spanish: 'GOTERO LLENO' },
  {
    code: 'DTI',
    english: 'DOUBLE OR TRIPLE FOR ILLNESS',
    spanish: 'DOBLE O TRIPLE PARA LA ENFERMEDAD',
  },
  { code: 'DVD', english: 'DOS VECES AL DIA', spanish: 'DOS VECES AL DIA' },
  { code: 'EACH', english: 'EACH', spanish: 'CADA' },
  { code: 'EC', english: 'EACH CHEEK', spanish: 'CADA MEJILLA' },
  { code: 'EED', english: 'EN EL OIDO DERECHO', spanish: 'EN EL OIDO DERECHO' },
  {
    code: 'EMR',
    english: 'MAY REPEAT EVERY 2-3 MIN IF EMERGENCY PERSISTS.',
    spanish: 'NULL',
  },
  { code: 'EOD', english: 'IN THE RIGHT EAR', spanish: 'EN EL OJO DERECHO' },
  { code: 'EOS', english: 'IN THE LEFT EAR', spanish: 'EN EL OJO IZQUIERDO' },
  { code: 'EVE', english: 'EVENING', spanish: 'TARDE' },
  { code: 'EXP', english: 'EXPECTORATE', spanish: 'NULL' },
  { code: 'EXT', english: 'EXTERNALLY', spanish: 'EXTERNAMENTE' },
  {
    code: 'FEV',
    english: 'AS NEEDED FOR FEVER',
    spanish: 'CUANDO SEA NECESARIO PARA LA FIEBRE',
  },
  {
    code: 'FEVP',
    english: 'AS NEEDED FOR FEVER AND PAIN',
    spanish: 'CUANDO SEA NECESARIO PARA LA FIEBRE Y DOLOR',
  },
  { code: 'FID', english: 'FIVE TIMES DAILY', spanish: 'CINCO VECES AL DIA' },
  { code: 'FIVE', english: 'FIVE', spanish: 'CINCO' },
  { code: 'FOR', english: 'FOR', spanish: 'POR' },
  { code: 'FOUR', english: 'FOUR', spanish: 'CUATRO' },
  {
    code: 'FPD',
    english: 'FOLLOW PACKAGE DIRECTIONS',
    spanish: 'SEGUIR INSTRUCCIONES DE EL PAQUETE',
  },
  { code: 'FRI', english: 'FRIDAY', spanish: 'VIERNES' },
  { code: 'G', english: 'GIVE', spanish: 'DAR' },
  { code: 'GA', english: 'GOTA', spanish: 'GOTA' },
  {
    code: 'GEF',
    english: 'GENERIC EQUIVALENT FOR',
    spanish: 'EQUIVALENTE GENERICO PARA',
  },
  {
    code: 'GEQ',
    english: 'GENERIC EQUIVALENT TO',
    spanish: 'EQUIVALENTE GENERICO DE',
  },
  { code: 'GF', english: 'GENERIC FOR', spanish: 'GENERICO PARA' },
  {
    code: 'GSM',
    english: 'GENERIC SUBSTITUTION MADE',
    spanish: 'SUSTITUCION HECHA GENERICA',
  },
  { code: 'GTB', english: 'GTUBE', spanish: 'TUBO-G' },
  { code: 'GTS', english: 'DROPS', spanish: 'GOTAS' },
  { code: 'GTT', english: 'DROP', spanish: 'GOTA' },
  { code: 'H', english: 'HOURS', spanish: 'HORAS' },
  { code: 'HA', english: 'HEADACHE', spanish: 'DOLOR DE CABEZA' },
  { code: 'HRA', english: 'HEART RATE', spanish: 'NULL' },
  { code: 'HS', english: 'AT BEDTIME', spanish: 'AL ACOSTARSE' },
  { code: 'I', english: 'INSERT', spanish: 'INSERTE' },
  { code: 'IEN', english: 'IN EACH NOSTRIL', spanish: 'EN CADA FOSA NASAL' },
  { code: 'IM', english: 'IN THE MUSCLE', spanish: 'EN EL MUSCULO' },
  { code: 'IMM', english: 'IMMEDIATELY', spanish: 'IMMEDIATAMENTE' },
  { code: 'IN', english: 'IN', spanish: 'EN' },
  {
    code: 'INH',
    english: 'INHALE 1 PUFFS EVERY 2 HOURS',
    spanish: '1 ATOMIZACION CADA 2 HORAS',
  },
  { code: 'INJ', english: 'INJECT', spanish: 'INYECTAR' },
  { code: 'INL', english: 'INHALE', spanish: 'INHALE' },
  {
    code: 'INS',
    english: 'MINUTES IF SYMPTOMS OF OPIOID EMERGENCY PERSIST',
    spanish: 'NULL',
  },
  { code: 'INT', english: 'INSTILL', spanish: 'INCULQUE' },
  { code: 'INTO', english: 'INTO', spanish: 'EN EL' },
  {
    code: 'INX',
    english: 'PREFILLED SYRINGE. MAY REPEAT EVERY 3-5 MINUTES',
    spanish: 'NULL',
  },
  {
    code: 'ISO',
    english: 'IF SYMPTOMS OF AN OPIOID EMERGENCY PERSIST.',
    spanish: 'NULL',
  },
  { code: 'ITL', english: 'INTO THE LUNGS', spanish: 'NULL' },
  {
    code: 'IVB',
    english: 'INSERT ONE APPLICATORFUL VAGINALLY AT BEDTIME',
    spanish: 'UN APLICADOR INTRAVAGINAL AL ACOSTARSE',
  },
  {
    code: 'IVS',
    english: 'INSERT ONE SUPPOSITORY VAGINALLY AT BEDTIME',
    spanish: 'INSERTE UN SUPOSITORIO INTRAVAGINAL AL ACOSTARSE',
  },
  { code: 'JTB', english: 'JTUBE', spanish: 'TUBO-J' },
  { code: 'LEA', english: 'IN THE LEFT EAR', spanish: 'EN EL OIDO IZQUIERDO' },
  { code: 'LEY', english: 'IN THE LEFT EYE', spanish: 'EN EL OJO IZQUIERDO' },
  { code: 'LOZ', english: 'LOZENGE', spanish: 'LOSANJE' },
  { code: 'LQ', english: 'LIQUID', spanish: 'LIQUIDO' },
  {
    code: 'MAD',
    english: 'NOSTRIL. USE WITH MAD. MAY REPEAT EVERY 3-5',
    spanish: 'NULL',
  },
  {
    code: 'MCD',
    english: 'MAY CAUSE DROWSINESS',
    spanish: 'PUEDE CAUSAR SOMNOLENCIA',
  },
  { code: 'MDD', english: 'MAXIMUM DAILY DOSE IS', spanish: 'NULL' },
  {
    code: 'MDX',
    english: 'NOSTRIL. USE WITH MAD. MAY REPEAT EVERY 3-5',
    spanish: 'NULL',
  },
  { code: 'MEAL', english: 'MEAL', spanish: 'COMIDA' },
  { code: 'MEQ', english: 'MEQ', spanish: 'MEQ' },
  { code: 'MG', english: 'MG', spanish: 'MG' },
  { code: 'MIN', english: 'MINUTES', spanish: 'MINUTOS' },
  { code: 'ML', english: 'ML', spanish: 'ML' },
  { code: 'MLS', english: 'MLS', spanish: 'MLS' },
  { code: 'MON', english: 'MONDAY', spanish: 'LUNES' },
  { code: 'MSP', english: 'MUSCLE SPASMS', spanish: 'NULL' },
  { code: 'MUS', english: 'INTO UPPER ARM OR THIGH MUSCLE.', spanish: 'NULL' },
  {
    code: 'MWF',
    english: 'MONDAY, WEDNESDAY, FRIDAY',
    spanish: 'LUNES, MIERCOLES, Y VIERNES',
  },
  { code: 'MX', english: 'MIX', spanish: 'MEZCLE' },
  { code: 'MXC', english: 'MIX 1 CAPFUL', spanish: 'MEZCLAR UNA TAPADERA' },
  {
    code: 'MXG',
    english: 'MX GRANULES IN 15 TO 30 ML OF ROOM TEMPERATURE TAP',
    spanish: 'NULL',
  },
  { code: 'N', english: 'FOR NAUSEA', spanish: 'PARA LAS NAUSEAS' },
  {
    code: 'NAR',
    english: 'CALL 911. SPR CONTENTS OF ONE SPRAYER (0.1ML)',
    spanish: 'LLAME AL 911. SPR EL CONTENIDO DE UN SPRY 0.1 ML',
  },
  {
    code: 'NAX',
    english: 'CALL 911. SPRAY 1/2 OF THE DOSE (1ML) INTO EACH',
    spanish: 'NULL',
  },
  { code: 'NEB', english: 'NEBULIZER', spanish: 'NEBULIZADOR' },
  { code: 'NER', english: 'FOR NERVES', spanish: 'PARA LOS NERVIOS' },
  { code: 'NEXT', english: 'NEXT', spanish: 'DESPUES' },
  { code: 'NGTB', english: 'NG-TUBE', spanish: 'TUBO-NG' },
  {
    code: 'NIJ',
    english: 'CALL 911. FOLLOW AUDIO INSTRUCTIONS.',
    spanish: 'NULL',
  },
  { code: 'NIM', english: 'CALL 911. ADMINISTER VIAL (1ML)', spanish: 'NULL' },
  {
    code: 'NM3',
    english: 'NO MORE THAN 3,000MG OF ACETAMINOPHEN DAILY',
    spanish: 'NULL',
  },
  {
    code: 'NM4',
    english: 'NO MORE THAN 4,000MG OF ACETAMINOPHEN DAILY',
    spanish: 'NO MAS DE 4,000MG DE ACETAMINOFENA POR DIA',
  },
  { code: 'NOS', english: 'NOSTRIL', spanish: 'FOSA NASAL' },
  { code: 'NPO', english: 'NOTHING BY MOUTH', spanish: 'NULL' },
  { code: 'NSY', english: 'CALL 911- USE WITH NALOXONE.', spanish: 'NULL' },
  { code: 'NTE', english: 'NOT TO EXCEED', spanish: 'NULL' },
  { code: 'NTS', english: 'NIGHTS', spanish: 'NOCHES' },
  {
    code: 'NV',
    english: 'FOR  NAUSEA AND VOMITING',
    spanish: 'PARA NAUSEA Y VOMITOS',
  },
  {
    code: 'OC',
    english: '1 D FOR A FULL CYCLE. STOP FOR 1 WEEK, THEN REPEAT',
    spanish: 'TOME 1 D POR UN CICLO. PARE POR 1 SEMANA Y REPITA',
  },
  { code: 'OD', english: 'RIGHT EYE', spanish: 'OJO DERECHO' },
  {
    code: 'OES',
    english: 'ON AN EMPTY STOMACH',
    spanish: 'CON EL ESTOMAGO VACIO',
  },
  { code: 'ONCE', english: 'ONCE', spanish: 'UNA VEZ' },
  { code: 'ONE', english: 'ONE', spanish: 'UNA' },
  { code: 'OR', english: 'OR', spanish: 'O' },
  { code: 'OS', english: 'LEFT EYE', spanish: 'OJO IZQUIERDO' },
  { code: 'OU', english: 'BOTH EYES', spanish: 'AMBOS OJOS' },
  { code: 'P', english: 'FOR PAIN', spanish: 'PARA EL DOLOR' },
  { code: 'PA', english: 'PATCH', spanish: 'PARCHE' },
  { code: 'PC', english: 'AFTER A MEAL', spanish: 'DESPUES DE LA COMIDA' },
  { code: 'PER', english: 'PER', spanish: 'POR' },
  { code: 'PFS', english: 'PUFFS', spanish: 'AUTOMIZACIONES' },
  { code: 'PO', english: 'BY MOUTH', spanish: 'POR LA BOCA' },
  {
    code: 'PPA',
    english: 'PARA LA PRESION ARTERIAL',
    spanish: 'PARA LA PRESION ARTERIAL',
  },
  {
    code: 'PRA',
    english: 'AS NEEDED FOR ANXIETY',
    spanish: 'CUANDO SEA NECESARIO PARA LA ANSIEDAD',
  },
  {
    code: 'PRF',
    english: 'AS NEEDED FOR',
    spanish: 'CUANDO SEA NECESARIO PARA',
  },
  { code: 'PRN', english: 'AS NEEDED', spanish: 'CUANDO SEA NECESARIO' },
  { code: 'PRO', english: 'PROCEDURE', spanish: 'NULL' },
  { code: 'PTA', english: 'PRIOR TO APPOINTMENT', spanish: 'NULL' },
  { code: 'PUE', english: 'PARA USO EXTERNO', spanish: 'PARA USO EXTERNO' },
  { code: 'Q', english: 'EVERY', spanish: 'CADA' },
  { code: 'QAFT', english: 'EVERY AFTERNOON', spanish: 'CADA TARDE' },
  { code: 'QAM', english: 'EVERY MORNING', spanish: 'CADA MANANA' },
  { code: 'QD', english: 'EVERY DAY', spanish: 'CADA DIA' },
  { code: 'QDW', english: '4 DAYS A WEEK', spanish: '4 DIAS A LA SEMANA' },
  {
    code: 'QHS',
    english: 'EVERY NIGHT AT BEDTIME',
    spanish: 'CADA NOCHE AL ACOSTARSE',
  },
  { code: 'QID', english: 'FOUR TIMES DAILY', spanish: 'CUATRO VECES AL DIA' },
  { code: 'QOD', english: 'EVERY OTHER DAY', spanish: 'CADA OTRO DIA' },
  { code: 'QPM', english: 'EVERY EVENING', spanish: 'CADA NOCHE' },
  { code: 'R', english: 'RECTAL', spanish: 'RECTAL' },
  { code: 'RE', english: 'REMOJAR', spanish: 'REMOJAR' },
  { code: 'REA', english: 'IN THE RIGHT EAR', spanish: 'EN EL OIDO DERECHO' },
  { code: 'REC', english: 'RECTALLY', spanish: 'RECTALMENTE' },
  {
    code: 'REE',
    english: 'REPEAT EVERY 2-3 MIN IF EMERGENCY PERSISTS',
    spanish: 'NULL',
  },
  {
    code: 'REP',
    english: 'INTO ONE NOSTRIL. REPEAT IN 2-3 MIN IF SYMPTOMS',
    spanish: 'EN UNA NOS. REPITA EN 2-3 MINUTOS SILOS SINTOMAS DE',
  },
  { code: 'REY', english: 'IN THE RIGHT EYE', spanish: 'EN EL OJO DERECHO' },
  { code: 'RF', english: 'REFRIGERATE', spanish: 'REFRIGERAR' },
  { code: 'RM', english: 'RINSE MOUTH', spanish: 'NULL' },
  {
    code: 'RVN',
    english: 'USE 1 RESPULE VIA NEBULIZER',
    spanish: 'USE UNA RESPULE VIA NEBULIZADOR',
  },
  { code: 'SAI', english: 'SEE ATTACHED INSTRUCTION SHEET.', spanish: 'NULL' },
  { code: 'SAS', english: 'SWISH AND SWALLOW', spanish: 'REVOLVER Y TRAGAR' },
  { code: 'SAT', english: 'SATURDAY', spanish: 'SABADO' },
  { code: 'SBP', english: 'SYSTOLIC BLOOD PRESSURE', spanish: 'NULL' },
  { code: 'SC', english: 'UNDER THE SKIN', spanish: 'DEBAJO DE LA PIEL' },
  { code: 'SI', english: 'SEGUN INDICADO', spanish: 'SEGUN INDICADO' },
  { code: 'SIX', english: 'SIX', spanish: 'SEIS' },
  { code: 'SLP', english: 'SLEEP', spanish: 'DORMIR' },
  { code: 'SML', english: 'SMALL', spanish: 'PEQUENA' },
  { code: 'SND', english: 'SUNDAY', spanish: 'DOMINGO' },
  { code: 'SOA', english: 'SOAK', spanish: 'REMOJAR' },
  {
    code: 'SOB',
    english: 'SHORTNESS OF BREATH',
    spanish: 'DIFICULTAD RESPIRATORIA',
  },
  {
    code: 'SPC',
    english: 'INL CONTENTS OF 1 C ONCE DAILY USING HANDIHALER',
    spanish: 'NULL',
  },
  { code: 'SPR', english: 'SPRAY', spanish: 'AUTOMIZE' },
  { code: 'SPRY', english: 'SPRAYER', spanish: 'AUTOMIZADOR' },
  { code: 'SPS', english: 'SUPPOSITORIES', spanish: 'SUPOSITORIOS' },
  { code: 'SQ', english: 'SUBCUTANEOUS', spanish: 'NULL' },
  { code: 'SS', english: 'ONE-HALF', spanish: 'UNA MITAD DE' },
  { code: 'SSP', english: 'SWISH AND SPIT', spanish: 'NULL' },
  { code: 'SU', english: 'SUPOSITORIO', spanish: 'SUPOSITORIO' },
  { code: 'SUP', english: 'SUPPOSITORY', spanish: 'SUPOSITORIO' },
  { code: 'SUS', english: 'SUPOSITORIOS', spanish: 'SUPOSITORIOS' },
  { code: 'SW', english: 'SHAKE LIQUID WELL', spanish: 'AGITAR LIQUIDO BIEN' },
  {
    code: 'SWR',
    english: 'SHAKE WELL AND REFRIGERATE',
    spanish: 'AGITAR BIEN Y REFRIGERAR',
  },
  { code: 'T', english: 'TABLET', spanish: 'TABLETA' },
  { code: 'TA', english: 'TABLETA', spanish: 'TABLETA' },
  {
    code: 'TAB',
    english: 'TAKE ONE TABLET EVERY 1 HOURS',
    spanish: 'TOME UNA TABLETA CADA 1 HORAS',
  },
  { code: 'TAS', english: 'TABLETAS', spanish: 'TABLETAS' },
  { code: 'TAT', english: 'UNTIL ALL TAKEN', spanish: 'HASTA TERMINAR' },
  { code: 'TAY', english: 'TODAY', spanish: 'HOY' },
  { code: 'TBL', english: 'TABLESPOONFUL', spanish: 'CUCHARADA' },
  {
    code: 'TBS',
    english: 'TAKE 1 TABLETS EVERY 2 HOURS',
    spanish: 'TOME 1 TABLETAS CADA 2 HORAS',
  },
  {
    code: 'TCH',
    english: 'TOME 1 CAPSULAS CADA 2 HORAS',
    spanish: 'TOME 1 CAPSULAS CADA 2 HORAS',
  },
  {
    code: 'TDL',
    english: 'UNA TA DEBAJO DE LA LENGUA CUANDO SEA NECESARIO',
    spanish: 'UNA TA DEBAJO DE LA LENGUA CUANDO SEA NECESARIO',
  },
  { code: 'TDW', english: '3 DAYS A WEEK', spanish: '3 DIAS A LA SEMANA' },
  { code: 'TEA', english: 'TEASPOONFUL', spanish: 'CUCHARADITA' },
  { code: 'TES', english: 'TEASPOONSFUL', spanish: 'CUCHARADITAS' },
  { code: 'THEN', english: 'THEN', spanish: 'Y LUEGO' },
  { code: 'THUR', english: 'THURSDAY', spanish: 'JUEVES' },
  { code: 'TID', english: 'THREE TIMES DAILY', spanish: 'TRES VECES AL DIA' },
  { code: 'TK', english: 'TAKE', spanish: 'TOME' },
  { code: 'TLS', english: 'TABLESPOONSFUL', spanish: 'CUCHARADA' },
  { code: 'TM', english: 'TAKE', spanish: 'TOME' },
  {
    code: 'TNT',
    english: 'ONE TABLET UNDER TONGUE AS NEEDED FOR CHEST PAIN',
    spanish: 'COLOQUE UNA T SUBLINGUAL PRF DOLOR DE PECHO',
  },
  { code: 'TS', english: 'TABLETS', spanish: 'TABLETS' },
  {
    code: 'TTH',
    english: 'TOME 1 TABLETAS CADA 2 HORAS',
    spanish: 'TOME 1 TABLETAS CADA 2 HORAS',
  },
  { code: 'TUE', english: 'TUESDAY', spanish: 'MARTES' },
  { code: 'TVD', english: 'TRES VECES AL DIA', spanish: 'TRES VECES AL DIA' },
  {
    code: 'TW',
    english: 'ONE TABLET IN WATER',
    spanish: 'UNA TABLETA EN AGUA',
  },
  { code: 'TWO', english: 'TWO', spanish: 'DOS' },
  { code: 'U', english: 'USE', spanish: 'USAR' },
  { code: 'UNI', english: 'UNITS', spanish: 'UNIDADES' },
  {
    code: 'UNN',
    english:
      'USING THE DEVICE, BLOW (11MG) INTO EACH NOSTRIL AT ONSET OF MIGRAINE.',
    spanish: 'NULL',
  },
  { code: 'UNT', english: 'UNDER THE TONGUE', spanish: 'DEBAJO DE LA LENGUA' },
  { code: 'UNW', english: 'UNWRAP', spanish: 'DESENVUELVA' },
  { code: 'USN', english: 'UN DIA SI OTRO NO', spanish: 'UN DIA SI OTRO NO' },
  { code: 'UTD', english: 'AS DIRECTED', spanish: 'SEGUN INDICADO' },
  { code: 'VA', english: 'VAGINAL', spanish: 'VAGINAL' },
  { code: 'VAC', english: 'VACCINE', spanish: 'NULL' },
  { code: 'VAG', english: 'IN THE VAGINA', spanish: 'INTRAVAGINAL' },
  { code: 'VIO', english: 'INJECT 0.4ML INTO THIGH.', spanish: 'NULL' },
  { code: 'VOM', english: 'VOMITING', spanish: 'VOMITO' },
  {
    code: 'VVN',
    english: 'USE 1 VIAL VIA NEBULIZER',
    spanish: 'USE UN FRASCO VIA NEBULIZADOR',
  },
  { code: 'WA', english: 'WHILE AWAKE', spanish: 'MIENTRAS ESTE DESPIERTO' },
  { code: 'WAC', english: 'WITH A MEAL', spanish: 'NULL' },
  { code: 'WAF', english: 'WAFER', spanish: 'NULL' },
  { code: 'WC', english: 'WITH MEALS', spanish: 'CON COMIDAS' },
  { code: 'WED', english: 'WEDNESDAY', spanish: 'MIERCOLES' },
  { code: 'WF', english: 'WITH FOOD', spanish: 'CON COMIDA' },
  { code: 'WFJ', english: 'WITH FRUIT JUICE', spanish: 'CON JUGO DE FRUTA' },
  {
    code: 'WGD',
    english: 'WATER IN A GLASS CUP AND DRK ENTIRE CONTENT Q 12 H.',
    spanish: 'NULL',
  },
  { code: 'WHZ', english: 'WHEEZING', spanish: 'RESOLLAR' },
  { code: 'WITH', english: 'WITH', spanish: 'CON' },
  { code: 'WK', english: 'WEEK', spanish: 'NULL' },
  { code: 'WKS', english: 'WEEKS', spanish: 'SEMANAS' },
  { code: 'WOJ', english: 'WITH ORANGE JUICE', spanish: 'CON JUGO DE NARANJA' },
  { code: 'WS', english: 'WITH SPACER', spanish: 'NULL' },
  { code: 'XD', english: 'TIMES DAILY', spanish: 'VECES DIARIO' },
  {
    code: 'ZPK',
    english: 'TK 2 TS PO ON DAY 1, THEN TK 1 T PO D FOR 4 DAY',
    spanish: 'NUL',
  },
];

export const decodedSigs = [
  { code: 'AA', english: 'TO THE AFFECTED AREA' },
  { code: 'AB', english: 'BOTH' },
  { code: 'ABD', english: 'ABDOMINAL' },
  { code: 'AC', english: 'BEFORE A MEAL' },
  { code: 'AD', english: 'RIGHT EAR' },
  { code: 'ADC', english: 'ANTES DE LAS COMIDAS' },
  { code: 'ADM', english: 'ADMINISTER' },
  { code: 'AEA', english: 'IN AFFECTED EAR(S)' },
  { code: 'AEY', english: 'IN AFFECTED EYE(S)' },
  { code: 'AHA', english: 'A LA HORA DE ACOSTARSE' },
  { code: 'AIDO', english: 'AFTER INITIAL DOSE' },
  { code: 'ALN', english: 'OF OPIOID EMERGENCY PERSIST, ALTERNATE NOSTRILS' },
  { code: 'ALT', english: 'ALTERNATE' },
  { code: 'AMP', english: 'AMPULE' },
  { code: 'AMT', english: 'AMOUNT' },
  { code: 'AND', english: 'AND' },
  { code: 'AOS', english: 'AT ONSET' },
  { code: 'AP', english: 'APLICAR' },
  { code: 'APL', english: 'APPLICATORFUL' },
  { code: 'APP', english: 'APPLY' },
  { code: 'APR', english: 'APPLY TO RASH' },
  { code: 'APS', english: 'APPLICATORSFUL' },
  { code: 'ASD', english: 'APPLY SPARINGLY WITH EACH DIAPER CHANGE' },
  { code: 'ATC', english: 'AROUND THE CLOCK' },
  {
    code: 'ATM',
    english: 'CALL 911. USE AS DIRECTED WITH NALOXONE NEEDLELESS',
  },
  { code: 'ATN', english: 'AT NIGHT' },
  { code: 'AU', english: 'BOTH EARS' },
  { code: 'AVN', english: 'USE 1 AMPULE VIA NEBULIZER' },
  { code: 'B', english: 'BEFORE' },
  { code: 'BED', english: 'BEDTIME' },
  { code: 'BG', english: 'BLOOD GLUCOSE' },
  { code: 'BID', english: 'TWICE DAILY' },
  { code: 'BM', english: 'BOWEL MOVEMENT' },
  { code: 'BP', english: 'BLOOD PRESSURE' },
  { code: 'BRE', english: 'BREAKFAST' },
  { code: 'BS', english: 'BLOOD SUGAR' },
  { code: 'BTP', english: 'BREAKTHROUGH PAIN' },
  { code: 'C', english: 'CAPSULE' },
  { code: 'CA', english: 'CAPSULA' },
  { code: 'CAP', english: 'TAKE ONE CAPSULE EVERY %1 HOURS' },
  { code: 'CAS', english: 'CAPSULAS' },
  { code: 'CFL', english: 'CAPFUL' },
  { code: 'CH', english: 'CUCHARADA' },
  { code: 'CHD', english: 'CUCHARADITA' },
  { code: 'CNC', english: 'COUGH AND CONGESTION' },
  { code: 'CNTS', english: 'CONTENTS OF 1 PACKET' },
  { code: 'COG', english: 'CONGESTION' },
  { code: 'CON', english: 'CONGESTION' },
  { code: 'CONS', english: 'CONSECUTIVE' },
  { code: 'CONT', english: 'CONTINUE' },
  { code: 'COU', english: 'FOR COUGH' },
  { code: 'CP', english: 'CHEST PAIN' },
  { code: 'CPS', english: 'TAKE %1 CAPSULES EVERY %2 HOURS' },
  { code: 'CRU', english: 'CRUSH' },
  { code: 'CS', english: 'CAPSULES' },
  { code: 'CSN', english: 'CUANDO SEA NECESARIO' },
  { code: 'CSW', english: 'CHEW AND SWALLOW' },
  { code: 'CVD', english: 'CUATRO VECES AL DIA' },
  { code: 'D', english: 'DAILY' },
  { code: 'DAPP', english: 'DENTAL APPOINTMENT' },
  { code: 'DAYS', english: 'DAYS' },
  { code: 'DBP', english: 'DIASTOLIC BLOOD PRESSURE' },
  { code: 'DDC', english: 'DESPUES DE LAS COMIDAS' },
  { code: 'DH', english: 'DIARRHEA' },
  { code: 'DI', english: 'DIARIAMENTE' },
  { code: 'DID', english: 'TWO OR THREE TIMES DAILY FOR WEIGHT LOSS' },
  { code: 'DIS', english: 'DISSOLVE' },
  { code: 'DIW', english: 'DISSOLVE %1 TABLETS IN WATER' },
  {
    code: 'DNC',
    english: 'TAKE FOUR CAPSULES BY MOUTH 1 HOUR BEFORE DENTAL APPOINTMENT',
  },
  {
    code: 'DNT',
    english: 'TAKE FOUR TABLETS BY MOUTH 1 HOUR BEFORE DENTAL APPOINTMENT',
  },
  { code: 'DPS', english: 'DROPPERSFUL' },
  { code: 'DR', english: 'DISCARD REMAINDER' },
  { code: 'DRK', english: 'DRINK' },
  { code: 'DRP', english: 'DROPPERFUL' },
  { code: 'DTI', english: 'DOUBLE OR TRIPLE FOR ILLNESS' },
  { code: 'DVD', english: 'DOS VECES AL DIA' },
  { code: 'EACH', english: 'EACH' },
  { code: 'EC', english: 'EACH CHEEK' },
  { code: 'EED', english: 'EN EL OIDO DERECHO' },
  {
    code: 'EMR',
    english: 'MAY REPEAT EVERY 2-3 MINUTES IF EMERGENCY PERSISTS.',
  },
  { code: 'EOD', english: 'EN EL OJO DERECHO' },
  { code: 'EOS', english: 'EN EL OJO IZQUIERDO' },
  { code: 'EVE', english: 'EVENING' },
  { code: 'EXP', english: 'EXPECTORATE' },
  { code: 'EXT', english: 'EXTERNALLY' },
  { code: 'FEV', english: 'AS NEEDED FOR FEVER' },
  { code: 'FEVP', english: 'AS NEEDED FOR FEVER AND PAIN' },
  { code: 'FID', english: 'FIVE TIMES DAILY' },
  { code: 'FIVE', english: 'FIVE' },
  { code: 'FOR', english: 'FOR' },
  { code: 'FOUR', english: 'FOUR' },
  { code: 'FPD', english: 'FOLLOW PACKAGE DIRECTIONS' },
  { code: 'FRI', english: 'FRIDAY' },
  { code: 'G', english: 'GIVE' },
  { code: 'GA', english: 'GOTA' },
  { code: 'GEF', english: 'GENERIC EQUIVALENT FOR' },
  { code: 'GEQ', english: 'GENERIC EQUIVALENT TO' },
  { code: 'GF', english: 'GENERIC FOR' },
  { code: 'GSM', english: 'GENERIC SUBSTITUTION MADE' },
  { code: 'GTB', english: 'GTUBE' },
  { code: 'GTS', english: 'DROPS' },
  { code: 'GTT', english: 'DROP' },
  { code: 'H', english: 'HOURS' },
  { code: 'HA', english: 'HEADACHE' },
  { code: 'HRA', english: 'HEART RATE' },
  { code: 'HS', english: 'AT BEDTIME' },
  { code: 'I', english: 'INSERT' },
  { code: 'IEN', english: 'IN EACH NOSTRIL' },
  { code: 'IM', english: 'IN THE MUSCLE' },
  { code: 'IMM', english: 'IMMEDIATELY' },
  { code: 'IN', english: 'IN' },
  { code: 'INH', english: 'INHALE %1 PUFFS EVERY %2 HOURS' },
  { code: 'INJ', english: 'INJECT' },
  { code: 'INL', english: 'INHALE' },
  { code: 'INS', english: 'MINUTES IF SYMPTOMS OF OPIOID EMERGENCY PERSIST' },
  { code: 'INT', english: 'INSTILL' },
  { code: 'INTO', english: 'INTO' },
  { code: 'INX', english: 'PREFILLED SYRINGE. MAY REPEAT EVERY 3-5 MINUTES' },
  { code: 'ISO', english: 'IF SYMPTOMS OF AN OPIOID EMERGENCY PERSIST.' },
  { code: 'ITL', english: 'INTO THE LUNGS' },
  { code: 'IVB', english: 'INSERT ONE APPLICATORFUL VAGINALLY AT BEDTIME' },
  { code: 'IVS', english: 'INSERT ONE SUPPOSITORY VAGINALLY AT BEDTIME' },
  { code: 'JTB', english: 'JTUBE' },
  { code: 'LEA', english: 'IN THE LEFT EAR' },
  { code: 'LEY', english: 'IN THE LEFT EYE' },
  { code: 'LOZ', english: 'LOZENGE' },
  { code: 'LQ', english: 'LIQUID' },
  { code: 'MCD', english: 'MAY CAUSE DROWSINESS' },
  { code: 'MDD', english: 'MAXIMUM DAILY DOSE IS' },
  { code: 'MEAL', english: 'MEAL' },
  { code: 'MEQ', english: 'MEQ' },
  { code: 'MG', english: 'MG' },
  { code: 'MIN', english: 'MINUTES' },
  { code: 'ML', english: 'ML' },
  { code: 'MLS', english: 'MLS' },
  { code: 'MON', english: 'MONDAY' },
  { code: 'MSP', english: 'MUSCLE SPASMS' },
  { code: 'MUS', english: 'INTO UPPER ARM OR THIGH MUSCLE.' },
  { code: 'MWF', english: 'MONDAY, WEDNESDAY, FRIDAY' },
  { code: 'MX', english: 'MIX' },
  { code: 'MXC', english: 'MIX 1 CAPFUL' },
  {
    code: 'MXG',
    english: 'MIX GRANULES IN 15 TO 30 ML OF ROOM TEMPERATURE TAP',
  },
  { code: 'N', english: 'FOR NAUSEA' },
  { code: 'NAR', english: 'CALL 911. SPRAY CONTENTS OF ONE SPRAYER (0.1ML)' },
  { code: 'NAX', english: 'CALL 911. SPRAY 1/2 OF THE DOSE (1ML) INTO EACH' },
  { code: 'NEB', english: 'NEBULIZER' },
  { code: 'NER', english: 'FOR NERVES' },
  { code: 'NEXT', english: 'NEXT' },
  { code: 'NGTB', english: 'NG-TUBE' },
  { code: 'NIJ', english: 'CALL 911. FOLLOW AUDIO INSTRUCTIONS.' },
  { code: 'NIM', english: 'CALL 911. ADMINISTER VIAL (1ML)' },
  { code: 'NM3', english: 'NO MORE THAN 3,000MG OF ACETAMINOPHEN DAILY' },
  { code: 'NM4', english: 'NO MORE THAN 4,000MG OF ACETAMINOPHEN DAILY' },
  { code: 'NOS', english: 'NOSTRIL' },
  { code: 'NPO', english: 'NOTHING BY MOUTH' },
  { code: 'NSY', english: 'CALL 911- USE WITH NALOXONE.' },
  { code: 'NTE', english: 'NOT TO EXCEED' },
  { code: 'NTS', english: 'NIGHTS' },
  { code: 'NV', english: 'FOR NAUSEA AND VOMITING' },
  {
    code: 'OC',
    english: '1 DAILY FOR A FULL CYCLE. STOP FOR 1 WEEK, THEN REPEAT',
  },
  { code: 'OD', english: 'RIGHT EYE' },
  { code: 'OES', english: 'ON AN EMPTY STOMACH' },
  { code: 'ONCE', english: 'ONCE' },
  { code: 'ONE', english: 'ONE' },
  { code: 'OR', english: 'OR' },
  { code: 'OS', english: 'LEFT EYE' },
  { code: 'OU', english: 'BOTH EYES' },
  { code: 'P', english: 'FOR PAIN' },
  { code: 'PA', english: 'PATCH' },
  { code: 'PC', english: 'AFTER A MEAL' },
  { code: 'PER', english: 'PER' },
  { code: 'PFS', english: 'PUFFS' },
  { code: 'PO', english: 'BY MOUTH' },
  { code: 'PPA', english: 'PARA LA PRESION ARTERIAL' },
  { code: 'PRA', english: 'AS NEEDED FOR ANXIETY' },
  { code: 'PRF', english: 'AS NEEDED FOR' },
  { code: 'PRN', english: 'AS NEEDED' },
  { code: 'PRO', english: 'PROCEDURE' },
  { code: 'PTA', english: 'PRIOR TO APPOINTMENT' },
  { code: 'PUE', english: 'PARA USO EXTERNO' },
  { code: 'Q', english: 'EVERY' },
  { code: 'QAFT', english: 'EVERY AFTERNOON' },
  { code: 'QAM', english: 'EVERY MORNING' },
  { code: 'QD', english: 'EVERY DAY' },
  { code: 'QDW', english: '4 DAYS A WEEK' },
  { code: 'QHS', english: 'EVERY NIGHT AT BEDTIME' },
  { code: 'QID', english: 'FOUR TIMES DAILY' },
  { code: 'QOD', english: 'EVERY OTHER DAY' },
  { code: 'QPM', english: 'EVERY EVENING' },
  { code: 'R', english: 'RECTAL' },
  { code: 'RE', english: 'REMOJAR' },
  { code: 'REA', english: 'IN THE RIGHT EAR' },
  { code: 'REC', english: 'RECTALLY' },
  { code: 'REE', english: 'REPEAT EVERY 2-3 MINUTES IF EMERGENCY PERSISTS' },
  {
    code: 'REP',
    english: 'INTO ONE NOSTRIL. REPEAT IN 2-3 MINUTES IF SYMPTOMS',
  },
  { code: 'REY', english: 'IN THE RIGHT EYE' },
  { code: 'RF', english: 'REFRIGERATE' },
  { code: 'RM', english: 'RINSE MOUTH' },
  { code: 'RVN', english: 'USE 1 RESPULE VIA NEBULIZER' },
  { code: 'SAI', english: 'SEE ATTACHED INSTRUCTION SHEET.' },
  { code: 'SAS', english: 'SWISH AND SWALLOW' },
  { code: 'SAT', english: 'SATURDAY' },
  { code: 'SBP', english: 'SYSTOLIC BLOOD PRESSURE' },
  { code: 'SC', english: 'UNDER THE SKIN' },
  { code: 'SI', english: 'SEGUN INDICADO' },
  { code: 'SIX', english: 'SIX' },
  { code: 'SLP', english: 'SLEEP' },
  { code: 'SML', english: 'SMALL' },
  { code: 'SND', english: 'SUNDAY' },
  { code: 'SOA', english: 'SOAK' },
  { code: 'SOB', english: 'SHORTNESS OF BREATH' },
  {
    code: 'SPC',
    english: 'INHALE CONTENTS OF 1 CAPSULE ONCE DAILY USING HANDIHALER',
  },
  { code: 'SPR', english: 'SPRAY' },
  { code: 'SPRY', english: 'SPRAYER' },
  { code: 'SPS', english: 'SUPPOSITORIES' },
  { code: 'SQ', english: 'SUBCUTANEOUS' },
  { code: 'SS', english: 'ONE-HALF' },
  { code: 'SSP', english: 'SWISH AND SPIT' },
  { code: 'SU', english: 'SUPOSITORIO' },
  { code: 'SUP', english: 'SUPPOSITORY' },
  { code: 'SUS', english: 'SUPOSITORIOS' },
  { code: 'SW', english: 'SHAKE LIQUID WELL' },
  { code: 'SWR', english: 'SHAKE WELL AND REFRIGERATE' },
  { code: 'T', english: 'TABLET' },
  { code: 'TA', english: 'TABLETA' },
  { code: 'TAB', english: 'TAKE ONE TABLET EVERY %1 HOURS' },
  { code: 'TAS', english: 'TABLETAS' },
  { code: 'TAT', english: 'UNTIL ALL TAKEN' },
  { code: 'TAY', english: 'TODAY' },
  { code: 'TBL', english: 'TABLESPOONFUL' },
  { code: 'TBS', english: 'TAKE %1 TABLETS EVERY %2 HOURS' },
  { code: 'TCH', english: 'TOME %1 CAPSULAS CADA %2 HORAS' },
  {
    code: 'TDL',
    english: 'UNA TABLETA DEBAJO DE LA LENGUA CUANDO SEA NECESARIO',
  },
  { code: 'TDW', english: '3 DAYS A WEEK' },
  { code: 'TEA', english: 'TEASPOONFUL' },
  { code: 'TES', english: 'TEASPOONSFUL' },
  { code: 'THEN', english: 'THEN' },
  { code: 'THUR', english: 'THURSDAY' },
  { code: 'TID', english: 'THREE TIMES DAILY' },
  { code: 'TK', english: 'TAKE' },
  { code: 'TLS', english: 'TABLESPOONSFUL' },
  { code: 'TM', english: 'TOME' },
  { code: 'TNT', english: 'ONE TABLET UNDER TONGUE AS NEEDED FOR CHEST PAIN' },
  { code: 'TS', english: 'TABLETS' },
  { code: 'TTH', english: 'TOME %1 TABLETAS CADA %2 HORAS' },
  { code: 'TUE', english: 'TUESDAY' },
  { code: 'TVD', english: 'TRES VECES AL DIA' },
  { code: 'TW', english: 'ONE TABLET IN WATER' },
  { code: 'TWO', english: 'TWO' },
  { code: 'U', english: 'USE' },
  { code: 'UNI', english: 'UNITS' },
  {
    code: 'UNN',
    english:
      'USING THE DEVICE, BLOW (11MG) INTO EACH NOSTRIL AT ONSET OF MIGRAINE.',
  },
  { code: 'UNT', english: 'UNDER THE TONGUE' },
  { code: 'UNW', english: 'UNWRAP' },
  { code: 'USN', english: 'UN DIA SEGUN INDICADO OTRO NO' },
  { code: 'UTD', english: 'AS DIRECTED' },
  { code: 'VA', english: 'VAGINAL' },
  { code: 'VAC', english: 'VACCINE' },
  { code: 'VAG', english: 'IN THE VAGINA' },
  { code: 'VIO', english: 'INJECT 0.4ML INTO THIGH.' },
  { code: 'VOM', english: 'VOMITING' },
  { code: 'VVN', english: 'USE 1 VIAL VIA NEBULIZER' },
  { code: 'WA', english: 'WHILE AWAKE' },
  { code: 'WAC', english: 'WITH A MEAL' },
  { code: 'WAF', english: 'WAFER' },
  { code: 'WC', english: 'WITH MEALS' },
  { code: 'WED', english: 'WEDNESDAY' },
  { code: 'WF', english: 'WITH FOOD' },
  { code: 'WFJ', english: 'WITH FRUIT JUICE' },
  {
    code: 'WGD',
    english: 'WATER IN A GLASS CUP AND DRINK ENTIRE CONTENT EVERY 12 HOURS.',
  },
  { code: 'WHZ', english: 'WHEEZING' },
  { code: 'WITH', english: 'WITH' },
  { code: 'WK', english: 'WEEK' },
  { code: 'WKS', english: 'WEEKS' },
  { code: 'WOJ', english: 'WITH ORANGE JUICE' },
  { code: 'WS', english: 'WITH SPACER' },
  { code: 'XD', english: 'TIMES DAILY' },
  {
    code: 'ZPK',
    english:
      'TAKE 2 TABLETS BY MOUTH ON DAY 1, THEN TAKE 1 TABLET BY MOUTH DAILY FOR 4 DAY',
  },
];
export const sigCodeOptions = decodedSigs.map((codeVal) => ({
  value: codeVal.code,
  label: codeVal.code,
}));

export const getSigCodeLabels = (): string[] => {
  return sigCodeOptions.map((option) => option.label);
};

export const topSigCodes = [
  'TK 1 T PO QD',
  'TK 1 T PO D',
  'TK 1 T PO BID',
  'ADM 0.5ML IM UTD',
  'TK 1 C PO QD',
  'TK 1 T PO TID',
  'TK 1 C PO D',
  'TK 1 T PO HS',
  'TK 1 T PO QHS',
  'TK 1 T PO  QD',
  'TK 1 T PO QAM',
  'TK 1 C PO BID',
  'TK 1 T PO  BID',
  'ADM 0.3ML IM UTD',
  'TK 1 T PO QD HS',
  'TK 1 T PO TID PRN',
  'FPD',
  'TK 1 C PO TID',
  'TK 1 T PO BID PRN',
  'TK 1 C PO QAM',
  'ADM 0.7ML IM UTD',
  'TK 1 T (10 MG) PO D',
  'TK 1 T PO  D',
  'TK 1 T PO Q 12 H',
  'APP EXT AA BID',
  'TK 1 T PO ONCE D',
  'TK 2 TS PO BID',
  'TK 1 T PO Q 6 H PRN',
  'TK 1 T PO Q NIGHT',
  'TK 2 TS PO QD',
  'TK 1 T PO QD IN THE MORNING',
  'TK 1 T PO QPM',
  'TK 2 TS PO ON DAY 1, THEN TK 1 T PO D FOR 4 DAYS',
  'TK 1 T (20 MG) PO D',
  'TK 1 T PO QD PRN',
  'TK 1 T PO Q 6 H PRF PAIN',
  'TK 1 T PO BID FOR 7 DAYS',
  'TK 2 TS PO D',
  'TK 1 T PO QID',
  'TK 1 T PO BID WC',
  'TM UNA TA DI',
  'TK 1 T PO Q 6 H PRN P',
  'TK 1 T PO BID FOR 10 DAYS',
  'TK 1 T (40 MG) PO D',
  'TEST UTD',
  'TK 1 T PO Q 8 H PRN',
  'INL 2 PFS PO BID',
  'TK 1 T (5 MG) PO D',
  'TK ONE C PO QD',
  'TK 1 T (25 MG) PO D',
  'U UTD',
  'UTD',
  'TK 1 T PO QD UTD',
  'TAKE 1 TABLET BY MOUTH ONCE DAILY',
  'TK 1 T PO  QHS',
  'TK 1 T PO ONCE A DAY',
  'TK 1 T PO QD IN THE EVE',
  'TK 1 T (50 MG) PO D',
  'TK ONE C PO BID',
  'TK 1 T PO  TID',
  'TK 1 T PO BID WF',
  'USE AS DIRECTED',
  'TK ONE C PO  QD',
  'TK 1 T (100 MG) PO D',
  'TK 1 T PO Q 8 H',
  'TK 1 C PO QD IN THE MORNING',
  'INL 1 PUFF PO BID',
  'TK 1 C PO HS',
  'TK 1 T PO QD IN THE MORNING OES',
  'TK 1 T PO Q 6 H',
  'TK ONE C PO  BID',
  'TK 1 T (10 MG) PO QD',
  'TK 1 T PO Q 12 H FOR 10 DAYS',
  'TK 1 C PO QHS',
  'TK ONE C PO D',
  'TK 1/2 T PO BID',
  'TK 1 T PO D UTD',
  'TK 1 T PO HS PRN',
  'INT 1 GTT IN OU BID',
  'TK 1 T PO QID PRN',
  'TK 1 T PO Q 8 H PRF PAIN',
  'TK UTD',
  'TK 1 C PO BID FOR 7 DAYS',
  'TK 2 CS PO BID',
  'USE UTD',
  'TEST AS DIRECTED',
  'INL 2 PFS PO Q 4 H PRN',
  'TK 1 T PO BID PRA',
  'TK 1/2 T PO QD',
  'TK 1 T PO Q 12 H FOR 7 DAYS',
  'SHAKE LQ AND U 2 SPRAYS IEN D',
  'TK 1 T PO  QAM',
  'SHAKE LQ AND U 2 SPRAYS IEN QD',
  'TK 1 T PO D HS',
  'TK 1 T PO QD WF',
  'TK 1 T PO D PRN',
  'TK 1 AND 1/2 TS PO QD',
  'TK ONE C PO QAM',
  'TK 1 C PO 1 TIME A W',
];

export const topDirections = [
  'TAKE 1 TABLET (20 MG) BY MOUTH EVERY DAY',
  'SHAKE LIQUID AND USE 1 SPRAY IN EACH NOSTRIL EVERY DAY',
  'ADMINISTER 0.3ML IN THE MUSCLE AS DIRECTED TODAY',
  'TAKE ONE CAPSULE BY MOUTH THREE TIMES DAILY',
  'TAKE 1/2 TABLET BY MOUTH DAILY',
  'TAKE 1 TABLET BY MOUTH TWICE DAILY AS NEEDED FOR PAIN',
  'TAKE 1 TABLET BY MOUTH  EVERY 6 HOURS AS NEEDED FOR PAIN',
  'TAKE 1 TABLET BY MOUTH EVERY NIGHT AT BEDTIME AS NEEDED',
  'TAKE 1 TABLET BY MOUTH EVERY DAY AT BEDTIME AS NEEDED',
  'TAKE 2 CAPSULES BY MOUTH EVERY DAY',
  'TAKE 1 TABLET BY MOUTH TWICE DAILY FOR 5 DAYS',
  'INHALE 2 PUFFS BY MOUTH EVERY 4 TO 6 HOURS AS NEEDED',
  'TAKE 1 AND 1/2 TABLETS BY MOUTH DAILY',
  'TAKE 1 TABLET BY MOUTH THREE TIMES DAILY AS NEEDED FOR PAIN',
  'TAKE 1 CAPSULE BY MOUTH TWICE DAILY FOR 10 DAYS',
  'TAKE 2 TABLETS BY MOUTH AT BEDTIME',
  'TAKE 1 TABLET BY MOUTH TWICE DAILY AS DIRECTED',
  'INHALE 1 PUFF BY MOUTH EVERY DAY',
  'TAKE 1 TABLET BY MOUTH  ONCE DAILY',
  'TAKE 1 TABLET BY MOUTH THREE TIMES DAILY AS NEEDED FOR ANXIETY',
  'TAKE ONE CAPSULE BY MOUTH  THREE TIMES DAILY',
  'TAKE 1 CAPSULE BY MOUTH ONCE DAILY IN THE MORNING',
  'TAKE 1 TABLET BY MOUTH EVERY DAY',
  'TAKE 2 TABLETS BY MOUTH EVERY NIGHT AT BEDTIME',
  'TAKE 1 TABLET BY MOUTH EVERY 8 HOURS AS NEEDED FOR PAIN',
  'TAKE 1 TABLET (40 MG) BY MOUTH EVERY DAY',
  'SHAKE LIQUID AND USE 1 SPRAY IN EACH NOSTRIL TWICE DAILY',
  'TAKE 1 TABLET BY MOUTH EVERY 12 HOURS AS NEEDED',
  'TAKE 1 CAPSULE BY MOUTH EVERY WK',
  'TAKE 1 TABLET BY MOUTH DAILY',
  'TAKE 2 CAPSULES BY MOUTH DAILY',
  'TAKE 1 TABLET BY MOUTH DAILY',
  'TAKE 1 CAPSULE (20 MG) BY MOUTH DAILY',
  'TAKE 1 CAPSULE BY MOUTH FOUR TIMES DAILY',
  'TAKE 1 TABLET BY MOUTH EVERY 4 HOURS AS NEEDED FOR PAIN',
  'TAKE 1 CAPSULE BY MOUTH EVERY 12 HOURS',
  'TAKE 1 TABLET BY MOUTH EVERY 4 HOURS AS NEEDED FOR PAIN',
  'TAKE 1 CAPSULE BY MOUTH WEEKLY',
  'TAKE 2 TABLETS BY MOUTH THREE TIMES DAILY',
  'TAKE 1 CAPSULE BY MOUTH EVERY DAY AT BEDTIME',
  'TAKE 1 TABLET (5 MG) BY MOUTH EVERY DAY',
  'TAKE 1 TABLET BY MOUTH EVERY 4 TO 6 HOURS AS NEEDED FOR PAIN',
  'TAKE ONE CAPSULE BY MOUTH  EVERY MORNING',
  'TAKE 1 TABLET BY MOUTH EVERY 4 HOURS AS NEEDED',
  'TAKE 1 TABLET (10 MG) BY MOUTH EVERY NIGHT',
  'TAKE ONE TABLET BY MOUTH EVERY DAY',
  'MIX AND DRINK AS DIRECTED',
  'TAKE 1 TABLET (25 MG) BY MOUTH EVERY DAY',
  'TAKE 1 TABLET (20 MG) BY MOUTH TWICE DAILY',
  'TAKE 1 TABLET (5 MG) BY MOUTH TWICE DAILY',
  'TAKE 1 TABLET BY MOUTH IN THE MORNING',
  'TAKE 1 CAPSULE (40 MG) BY MOUTH DAILY',
  'TAKE ONE CAPSULE BY MOUTH  DAILY',
  'TOME UNA TABLETA DOS VECES AL DIA',
  'CHEW AND SWALLOW 1 TABLET BY MOUTH EVERY DAY',
  'TAKE 2 CAPSULES BY MOUTH THREE TIMES DAILY',
  'INHALE 1 PUFF BY MOUTH DAILY',
  'TAKE 1 TABLET (500 MG) BY MOUTH TWICE DAILY WITH MEALS',
  'TAKE 3 TABLETS BY MOUTH EVERY DAY',
  'TAKE 1 TABLET (50 MG) BY MOUTH EVERY DAY',
  'TAKE 1 TABLET BY MOUTH  TWICE DAILY AS NEEDED',
  'TAKE 1 TABLET BY MOUTH TWICE DAILY WAC',
  'SHAKE LIQUID AND USE 1 SPRAY IN EACH NOSTRIL DAILY',
  'INHALE 2 PUFFS BY MOUTH EVERY 6 HOURS AS NEEDED',
  'TEST TWICE DAILY',
  'TAKE 1 CAPSULE BY MOUTH TWICE DAILY FOR 5 DAYS',
  'APPLY EXTERNALLY TO THE AFFECTED AREA THREE TIMES DAILY',
  'TAKE 1 TABLET BY MOUTH THREE TIMES DAILY FOR 7 DAYS',
  'TAKE 1 TABLET BY MOUTH TWICE DAILY WITH MEALS',
  'TAKE 1 TABLET (25 MG) BY MOUTH TWICE DAILY',
  'TAKE 1 TABLET (100 MG) BY MOUTH EVERY DAY',
  'TAKE 1 TABLET (50 MG) BY MOUTH TWICE DAILY',
  'APPLY THIN LAYER EXTERNALLY TO THE AFFECTED AREA TWICE DAILY',
  'TAKE 1 TABLET (500 MG) BY MOUTH TWICE DAILY',
  'TAKE 2 TABLETS BY MOUTH EVERY MORNING',
  'TAKE 1 TABLET BY MOUTH  AT BEDTIME',
  'TAKE 1 TABLET BY MOUTH TWICE DAILY FOR 14 DAYS',
  'TAKE 1 TABLET BY MOUTH EVERY OTHER DAY',
  'TAKE 1 CAPSULE BY MOUTH EVERY DAY WITH FOOD',
  'TOME 1 TABLETA DIARIAMENTE',
  'TAKE 1 TABLET BY MOUTH EVERY 4 TO 6 HOURS AS NEEDED FOR PAIN',
  'TAKE 1 CAPSULE BY MOUTH THREE TIMES DAILY AS NEEDED',
  'TAKE 1 TABLET BY MOUTH EVERY 4 TO 6 HOURS AS NEEDED',
  'TAKE 1 TABLET BY MOUTH  THREE TIMES DAILY AS NEEDED',
  'TAKE 1 TABLET (15 MG) BY MOUTH DAILY',
  'TAKE 1 TABLET BY MOUTH THREE TIMES DAILY AS NEEDED FOR PAIN',
  'TAKE 1 CAPSULE BY MOUTH THREE TIMES DAILY FOR 7 DAYS',
  'TAKE 1 TABLET BY MOUTH  EVERY 6 HOURS AS NEEDED',
  'TAKE 1 TABLET BY MOUTH EVERY 24 HOURS',
  'TAKE 3 TABLETS BY MOUTH DAILY',
  'TAKE 1 CAPSULE BY MOUTH THREE TIMES DAILY FOR 10 DAYS',
  'TAKE 1 TABLET BY MOUTH EVERY DAY FOR BLOOD PRESSURE',
  'TAKE 1 TABLET BY MOUTH  EVERY 12 HOURS',
  'APPLY TO THE AFFECTED AREA TWICE DAILY',
  'INHALE 2 PUFFS BY MOUTH TWICE DAILY',
  'ADMINISTER 0.5ML IN THE MUSCLE AS DIRECTED TODAY',
  'APPLY EXTERNALLY TO THE AFFECTED AREA EVERY DAY',
];

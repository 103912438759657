/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { ITableProps } from './Table.types';
import { HeaderGroup, usePagination, useTable } from 'react-table';
import './Table.scss';
import classNames from 'classnames';
import { CurrentRequest, Status } from '../../utils/dataBuilder/types';
import PaginationControls from '../PaginationControls';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { PAGE_SIZE } from '../../mocks/constants';

const Table: React.FC<ITableProps> = ({
  data,
  columns,
  totalCount,
  fetchData,
  loading,
  noDataComponent,
  setPageIndex,
}): React.ReactElement => {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: Math.ceil(totalCount / PAGE_SIZE),
    },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = tableInstance;

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    setPageIndex && setPageIndex(pageIndex);
    fetchData(pageIndex);
  }, [fetchData, pageIndex]);

  const getColumnStyle = (column: HeaderGroup<object>) => {
    let minWidth = '150px';
    if (column.id === 'actions') {
      return column.getHeaderProps().style;
    }
    switch (column.id) {
      case 'language':
        minWidth = '120px';
        break;
      case 'status':
        minWidth = '200px';
        break;
    }
    return {
      ...column.getHeaderProps().style,
      minWidth,
    };
    // }
    // return column.getHeaderProps().style;
  };

  return loading ? (
    <LoadingSpinner />
  ) : data.length ? (
    <>
      <PaginationControls
        pagination={{
          totalCount,
          startCount: pageIndex * PAGE_SIZE + 1,
          endCount: Math.min(PAGE_SIZE * (pageIndex + 1), totalCount),
        }}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        previousPage={previousPage}
      />
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => (
                <th
                  {...column.getHeaderProps()}
                  key={j}
                  className="table__col-header"
                  style={{ ...getColumnStyle(column) }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading ? (
            <tr className="table__row--loading">
              <td className="table__cell" colSpan={columns.length}>
                <LoadingSpinner />
              </td>
            </tr>
          ) : (
            page.map((row, i) => {
              prepareRow(row);
              const isRequestComplete =
                (row.original as CurrentRequest).status ===
                Status.TranslationComplete;
              const isRequestDownloaded =
                (row.original as CurrentRequest).status ===
                  Status.TranslationDownloaded ||
                !(row.original as CurrentRequest).status;
              return (
                <tr
                  {...row.getRowProps()}
                  key={i}
                  className={classNames('table__row')}
                >
                  {row.cells.map((cell, j) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={j}
                        className={classNames('table__cell', {
                          'table__cell--complete': isRequestComplete,
                          'table__cell--downloaded': isRequestDownloaded,
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </>
  ) : (
    noDataComponent
  );
};

export default Table;

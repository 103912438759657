import React from 'react';
import { IFormInputProps } from './FormInput.types';
import './FormInput.scss';
import { ReactComponent as ErrorIcon } from '../../media/icons/error.svg';

const FormInput: React.FC<IFormInputProps> = ({ keyText, value, error }) => {
  return (
    <div className="form-input">
      <div className="form-input__key">{keyText}</div>
      <div className="form-input__value">
        {value}
        {error && (
          <div className="form-input__error">
            <ErrorIcon />
            <span className="form-input__error-message">{error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormInput;

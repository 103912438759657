import React from 'react';
import { IStatusPillProps } from './StatusPill.types';
import './StatusPill.scss';
import classNames from 'classnames';
import { Status } from '../../utils/dataBuilder/types';

const StatusPill: React.FC<IStatusPillProps> = ({ status }) => {
  let variant = 'waiting';
  if (status === Status.Translating) {
    variant = 'translating';
  }
  if (status === Status.TranslationComplete) {
    variant = 'complete';
  }
  if (status === Status.TranslationDownloaded) {
    variant = 'downloaded';
  }

  return (
    <div className={classNames('status', `status--${variant}`)}>{status}</div>
  );
};

export default StatusPill;

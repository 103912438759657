import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { startCase } from 'lodash';
// import NotoFont from '../../fonts/Noto_Sans/NotoSans-Bold.ttf';
import Arial from '../../fonts/arial-unicode-ms.ttf';

// Register font
Font.register({
  family: 'Arial',
  src: Arial,
});

// Create styles
const styleSheet = (translationSize: string, englishTranslationSize: string) =>
  StyleSheet.create({
    page: {
      backgroundColor: '#fff',
      padding: '1in',
    },

    topBox: {
      border: '1px #838282 solid',
      height: '0.975in',
      marginBottom: '0.05in',
      paddingBottom: '0.075in',
      paddingLeft: '1in',
      paddingRight: '0.075in',
      paddingTop: '0.075in',
      width: '3in',
    },

    btmBox: {
      border: '1px #838282 solid',
      height: '0.975in',
      paddingBottom: '0.075in',
      paddingLeft: '1in',
      paddingRight: '0.075in',
      paddingTop: '0.075in',
      width: '3in',
    },

    text: {
      fontFamily: 'Arial',
      fontSize: '9px',
    },

    textTranslations: {
      fontFamily: 'Arial',
      fontSize: translationSize,
    },

    englishTranslationText: {
      fontFamily: 'Arial',
      fontSize: englishTranslationSize,
    },
  });

interface IPdfTemplateProps {
  language: string;
  translation: string;
  englishTranslation: string;
}

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

// Create Document Component
export const PdfTempate: React.FC<IPdfTemplateProps> = ({
  language,
  translation,
  englishTranslation,
}): React.ReactElement => {
  const getTextSize = (textLength: number): string => {
    if (textLength > 130) {
      return '6.5px';
    }
    if (textLength > 80) {
      return '8px';
    }
    if (textLength > 50) {
      return '9.5px';
    }
    return '11px';
  };

  console.log('englishTranslation', englishTranslation.length);
  console.log('translation', translation.length);

  const translationSize = getTextSize(translation.length);
  const englishTranslationSize = getTextSize(englishTranslation.length);

  const styles = styleSheet(translationSize, englishTranslationSize);

  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.topBox}>
          <Text style={styles.text}>{startCase(language)}:</Text>
          <Text style={styles.textTranslations}>
            {translation.toLocaleUpperCase()}
          </Text>
        </View>
        <View style={styles.btmBox}>
          <Text style={styles.text}>English:</Text>
          <Text style={styles.englishTranslationText}>
            {englishTranslation.toLocaleUpperCase()}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

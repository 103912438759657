import faker from 'faker';
import { getLanguageLabels } from '../../languages';
import { topDirections, topSigCodes } from '../../sigCodes';
import { statuses, translators } from './constants';
import { CurrentRequest, Status } from './types';
import { capitalizeFirstLetter, createRxNumber } from './utils';

const createCurrentRequest = (
  index: number,
  listSize: number,
  currentTime: number
): CurrentRequest => {
  const includeAdditionalText = Math.random() < 0.5;
  const translator = `Translator ${
    translators[Math.floor(Math.random() * translators.length)]
  }`;
  const contentToTranslate = includeAdditionalText
    ? capitalizeFirstLetter(
        topDirections[
          Math.floor(Math.random() * topDirections.length)
        ].toLowerCase()
      )
    : topSigCodes[Math.floor(Math.random() * topSigCodes.length)];

  const elapsedTime = includeAdditionalText
    ? 14 - Math.floor(Math.random() * 14)
    : 0;
  const requestTime = includeAdditionalText
    ? new Date(currentTime - elapsedTime * 60000)
    : new Date(currentTime - Math.random() * 15 * 60000);
  let status = statuses[Math.floor(Math.random() * statuses.length)];
  if (!includeAdditionalText || index === 0) {
    status = Status.TranslationComplete;
  }

  const languages = getLanguageLabels();
  let language = languages[Math.floor(Math.random() * languages.length)];
  if (Math.random() < 0.65) {
    language = 'Spanish';
  }

  return {
    id: faker.datatype.uuid().toString(),
    rxNumber: createRxNumber(),
    language,
    status,
    requestTime,
    elapsedTime,
    translator: includeAdditionalText ? translator : undefined,
    contentToTranslate,
  };
};

const createCurrentRequestsForDemo = (currentTime: number) => {
  const waitTimeForAdditionalText1 = 14 - Math.floor(Math.random() * 14);
  const waitTimeForAdditionalText2 = 14 - Math.floor(Math.random() * 14);

  return [
    {
      id: faker.datatype.uuid().toString(),
      rxNumber: createRxNumber(),
      language: 'Spanish',
      status: Status.TranslationComplete,
      requestTime: new Date(currentTime - Math.random() * 15 * 60000),
      elapsedTime: 0,
      contentToTranslate:
        'TK 1 T PO D PRF MIGRAINE HEADACHE. MAY REPEAT DOSE AFTER 2 H UP TO A MAXIMUM OF 200 MG IN 24 H',
    },
    {
      id: faker.datatype.uuid().toString(),
      rxNumber: createRxNumber(),
      language: 'Chinese',
      status: Status.TranslationComplete,
      requestTime: new Date(currentTime - Math.random() * 15 * 60000),
      elapsedTime: 0,
      contentToTranslate: 'TK 5 ML PO HS',
    },
    {
      id: faker.datatype.uuid().toString(),
      rxNumber: createRxNumber(),
      language: 'Korean',
      status: Status.Translating,
      requestTime: new Date(currentTime - waitTimeForAdditionalText2 * 60000),
      elapsedTime: waitTimeForAdditionalText2,
      contentToTranslate: 'Dissolve tablet by mouth',
      translator: 'Translator A',
    },
    {
      id: faker.datatype.uuid().toString(),
      rxNumber: createRxNumber(),
      language: 'Chinese',
      status: Status.WaitingForTranslator,
      requestTime: new Date(currentTime - waitTimeForAdditionalText1 * 60000),
      elapsedTime: waitTimeForAdditionalText1,
      contentToTranslate: 'Apply topically every day',
    },
  ];
};

const compare = (a: CurrentRequest, b: CurrentRequest): number => {
  if (
    a.status !== Status.TranslationComplete &&
    b.status === Status.TranslationComplete
  ) {
    return 1;
  }
  if (
    a.status === Status.TranslationComplete &&
    b.status !== Status.TranslationComplete
  ) {
    return -1;
  }
  // both have status = translation complete
  if (
    a.status === Status.TranslationComplete &&
    b.status === Status.TranslationComplete
  ) {
    return a.requestTime > b.requestTime ? -1 : 1;
  }
  if (
    a.status !== Status.TranslationComplete &&
    b.status !== Status.TranslationComplete
  ) {
    return a.requestTime > b.requestTime ? 1 : -1;
  }
  // a must be equal to b
  return 0;
};

export const createCurrentRequestsList = (
  listSize: number
): CurrentRequest[] => {
  // const currentRequestsList = [];
  // for (let i = 0; i < listSize; i++) {
  //   currentRequestsList.push(
  //     createCurrentRequest(i, listSize, new Date().getTime())
  //   );
  // }
  const currentRequestsList = createCurrentRequestsForDemo(
    new Date().getTime()
  );
  currentRequestsList.sort((a, b) => compare(a, b));
  return currentRequestsList;
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { IModalProps } from './Modal.types';
import { ReactComponent as CloseIcon } from '../../media/icons/close.svg';
import './Modal.scss';
import classNames from 'classnames';
import IconButton from '../IconButton';

const Modal: React.FC<IModalProps> = ({
  children,
  show,
  handleClose,
  className,
  header,
}) => {
  const node = React.useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (e: any) => {
    if (e.composedPath().includes(node.current)) {
      // inside click
      return;
    }
    // outside click
    handleClose();
  };

  React.useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return show ? (
    <div className="modal__background">
      <div className={classNames('modal', className)} ref={node}>
        <div className="modal__header">
          <h1>{header}</h1>
          <IconButton aria-label="close modal" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {children}
      </div>
    </div>
  ) : null;
};

export default Modal;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { rest } from 'msw';
import { createCurrentRequestsList } from '../utils/dataBuilder/currentRequests';
import { createPastRequestsList } from '../utils/dataBuilder/pastRequests';
import {
  PAGE_SIZE,
  TOTAL_CURRENT_REQUESTS,
  TOTAL_PAST_REQUESTS,
} from './constants';
import faker from 'faker';
import { Status } from '../utils/dataBuilder/types';
import { sigCodeTranslations } from '../sigCodes';
import { decodeSig } from '../utils/translation';

let allCurrentRequests = createCurrentRequestsList(TOTAL_CURRENT_REQUESTS);
const allPastRequests = createPastRequestsList(TOTAL_PAST_REQUESTS);

export const handlers = [
  rest.get('/currentRequests/count', (req, res, ctx) => {
    return res(
      ctx.json({
        count: allCurrentRequests.length,
      })
    );
  }),
  rest.get('/pastRequests/count', (req, res, ctx) => {
    return res(
      ctx.json({
        count: allPastRequests.length,
      })
    );
  }),
  rest.get('/currentRequests', (req, res, ctx) => {
    const pageIndex = parseInt(req.url.searchParams.get('page') || '0');
    const startRow = PAGE_SIZE * pageIndex;
    const endRow = startRow + PAGE_SIZE;
    allCurrentRequests = allCurrentRequests.map((currRequest) => {
      const timeDiffMs =
        new Date().getTime() - currRequest.requestTime.getTime();
      if (
        currRequest.status === Status.TranslationComplete ||
        currRequest.status === Status.TranslationDownloaded
      ) {
        return currRequest;
      }

      return {
        ...currRequest,
        elapsedTime: Math.floor(Math.abs(timeDiffMs) / 1000 / 60),
      };
    });
    return res(
      ctx.json({
        data: allCurrentRequests.slice(startRow, endRow),
      })
    );
  }),
  rest.get('/pastRequests', (req, res, ctx) => {
    const pageIndex = parseInt(req.url.searchParams.get('page') || '0');
    const startRow = PAGE_SIZE * pageIndex;
    const endRow = startRow + PAGE_SIZE;
    return res(
      ctx.json({
        data: allPastRequests.slice(startRow, endRow),
      })
    );
  }),
  rest.post('/cancelRequest', (req, res, ctx) => {
    const { id } = req.body as any;
    allCurrentRequests = allCurrentRequests.filter(
      (request) => request.id !== id
    );
    return res(ctx.status(200));
  }),
  rest.post('/download', (req, res, ctx) => {
    const { id: requestId } = req.body as any;
    const requestToDownload =
      allCurrentRequests.find((request) => request.id === requestId) ||
      allPastRequests.find((request) => request.id === requestId);
    const numCurrentRequests = allCurrentRequests.length;
    allCurrentRequests = allCurrentRequests.filter(
      (request) => request.id !== requestId
    );
    const updatedNumCurrentRequests = allCurrentRequests.length;

    if (requestToDownload) {
      const {
        id,
        rxNumber,
        language,
        requestTime,
        contentToTranslate,
        translator,
      } = requestToDownload;

      // if the request was a current request, add the request to the past request list
      if (numCurrentRequests > updatedNumCurrentRequests) {
        allPastRequests.unshift({
          id,
          rxNumber,
          language,
          requestTime,
          contentToTranslate,
          translator,
        });
      }

      if (
        contentToTranslate ===
        'TK 1 T PO D PRF MIGRAINE HEADACHE. MAY REPEAT DOSE AFTER 2 H UP TO A MAXIMUM OF 200 MG IN 24 H'
      ) {
        return res(
          ctx.status(200),
          ctx.json({
            rxNumber,
            language,
            originalText:
              'TAKE 1 TABLET BY MOUTH DAILY AS NEEDED FOR MIGRAINE HEADACHE. MAY REPEAT DOSE AFTER 2 HOURS UP TO A MAXIMUM OF 200 MG IN 24 HOURS',
            translation: 'TOME 1 TABLETA POR LA BOCA DIARIAMENTE CUANDO SEA NECESARIO PARA  la migraña. PUEDE REPETIR LA DOSIS DESPUÉS DE 2 HORAS HASTA UN MÁXIMO DE 200 MG EN 24 HORAS'.toUpperCase(),
          })
        );
      }

      if (contentToTranslate === 'TK 5 ML PO HS') {
        return res(
          ctx.status(200),
          ctx.json({
            rxNumber,
            language,
            originalText: 'TAKE 5 ML BY MOUTH AT BEDTIME',
            translation: '睡前口服5ml',
          })
        );
      }

      let originalText = contentToTranslate;
      // translate content to english
      if (!translator) {
        originalText = decodeSig(contentToTranslate);
      }
      let translation = originalText;
      // translate content to target language
      if (language.toLowerCase() === 'spanish') {
        const codes = contentToTranslate.split(' ');
        const translatedCodes = codes.map((code: string) => {
          const sigCode = sigCodeTranslations.find(
            (sigCode) => sigCode.code === code
          );
          return sigCode?.spanish || code;
        });
        translation = translatedCodes.join(' ');
      }
      return res(
        ctx.status(200),
        ctx.json({
          rxNumber,
          language,
          originalText,
          translation,
        })
      );
    }
    return res(ctx.status(400));
  }),
  rest.post('/addRequest', (req, res, ctx) => {
    const { rxNumber, language, sigCodes, additionalText } = req.body as any;
    const sigCodesString = sigCodes.join(' ');
    const contentToTranslate = additionalText.length
      ? sigCodesString
        ? sigCodesString + ', ' + additionalText
        : additionalText
      : sigCodesString;
    if (additionalText) {
      allCurrentRequests.push({
        id: faker.datatype.uuid().toString(),
        rxNumber,
        language,
        contentToTranslate,
        elapsedTime: 0,
        status: Status.WaitingForTranslator,
        requestTime: new Date(),
      });
    } else {
      allCurrentRequests.unshift({
        id: faker.datatype.uuid().toString(),
        rxNumber,
        language,
        contentToTranslate,
        elapsedTime: 0,
        status: Status.TranslationComplete,
        requestTime: new Date(),
      });
    }

    return res(ctx.status(200));
  }),
];

import React from 'react';
import { ToastContainer } from 'react-toastify';

import HomePage from './pages/HomePage';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/Login';
import { ProvideAuth, useAuth } from './utils/hooks/use-auth';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import ForgotPassword from './pages/ForgotPassword';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const App = () => {
  // A wrapper for <Route> that redirects to the login
  // screen if you're not yet authenticated.

  return (
    <div>
      <ToastContainer
        hideProgressBar={true}
        toastClassName="toast"
        bodyClassName="toast__body"
        // autoClose={false}
      />
      <ProvideAuth>
        <Router>
          <div>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/forgot-password">
                <ForgotPassword />
              </Route>
              <PrivateRoute path="/">
                <HomePage />
              </PrivateRoute>
            </Switch>
          </div>
        </Router>
      </ProvideAuth>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrivateRoute: React.FC<any> = ({ children, ...rest }) => {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default App;

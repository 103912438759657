/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Cell } from 'react-table';
import { formatDate } from '../../../utils/date';
import { ReactComponent as DownloadGrayIcon } from '../../../media/icons/download-button--gray.svg';
import { PastRequest } from '../../../utils/dataBuilder/types';
import { startCase } from 'lodash';
import IconButton from '../../../components/IconButton';

export const makePastActionsColumn = (
  handleDownload: (rxNumber: string, id: string, firstDownload: boolean) => void
) => ({
  Header: '',
  id: 'actions',
  Cell: (cell: Cell) => {
    const { id, rxNumber } = cell.row.original as PastRequest;
    return (
      <div className="download-button">
        <IconButton
          onClick={() => handleDownload(rxNumber, id, false)}
          aria-label="download translation for past request"
          tooltip="Download Translation"
        >
          <DownloadGrayIcon />
        </IconButton>
      </div>
    );
  },
});

export const columns = [
  {
    Header: 'Rx Number',
    accessor: 'rxNumber',
  },
  {
    Header: 'Language',
    accessor: 'language',
    Cell: (cell: Cell) => {
      return startCase(cell.value);
    },
  },
  {
    Header: 'Request Time',
    accessor: 'requestTime',
    Cell: (cell: Cell) => {
      const { value: requestTime } = cell;
      return formatDate(requestTime);
    },
  },
  {
    Header: 'Translator',
    accessor: 'translator',
  },
  {
    Header: 'Content to Translate',
    accessor: 'contentToTranslate',
  },
];

import React from 'react';
import { IPaginationControlsProps } from './PaginationControls.types';
import './PaginationControls.scss';
import { ReactComponent as DisabledBackArrowIcon } from '../../media/icons/back-arrow.svg';
import { ReactComponent as DisabledFrontArrowIcon } from '../../media/icons/front-arrow.svg';
import { ReactComponent as BackArrowIcon } from '../../media/icons/enabled-back-arrow.svg';
import { ReactComponent as FrontArrowIcon } from '../../media/icons/enabled-front-arrow.svg';
import Button from '../Button';
import IconButton from '../IconButton';

const PaginationControls: React.FC<IPaginationControlsProps> = ({
  pagination,
  canNextPage,
  canPreviousPage,
  nextPage,
  previousPage,
}) => {
  const { startCount, totalCount, endCount } = pagination;
  return (
    <div className="pagination-controls">
      <div className="pagination-controls__count">
        {`${startCount} - ${endCount} of ${
          totalCount === -1 ? '--' : totalCount
        }`}
      </div>
      <IconButton
        onClick={previousPage}
        disabled={!canPreviousPage}
        aria-label="previous page"
      >
        {!canPreviousPage ? <DisabledBackArrowIcon /> : <BackArrowIcon />}
      </IconButton>
      <IconButton
        onClick={nextPage}
        aria-label="next page"
        disabled={!canNextPage}
      >
        {!canNextPage ? <DisabledFrontArrowIcon /> : <FrontArrowIcon />}
      </IconButton>
    </div>
  );
};

export default PaginationControls;
